
function storeUserInformation(userInfo) {
    localStorage.setItem('userInfo', JSON.stringify({
        userId: userInfo.userId,
        fullName: userInfo.fullName,
        stationId: userInfo.stationId,
        permissions: userInfo.permissions
    }));
    localStorage.setItem('token', userInfo.token);
}

function getUserInfo(){
    const userInfoString = localStorage.getItem('userInfo');
    if (!userInfoString) {
        return null;
    }

    return JSON.parse(userInfoString);
}

function getToken() {
    return localStorage.getItem('token');
}

function clear(){
    localStorage.removeItem("userInfo")
    localStorage.removeItem('token');
}

module.exports = {
    storeUserInformation,
    getUserInfo,
    getToken,
    clear
}
    



//     return {
//         setToken,
//         getToken,
//         isAuthenticated
//     }
// }