import * as XLSX from 'xlsx-js-style';
import { moment } from '../../../utilities/moment';
import {
    IsNotAvailable
} from '../../../utilities/generalUti';
import {
    CombineDateObjectAndTimeObject,
    ConvertMinutesToMilliseconds,
    AddMinutesToTimeObject,
    RemoveTimeFromDateObject
} from '../../../utilities/dateAndTime';


const MERGE_CONFIG_INITIALIZE = [
    //
    {
        s: {
            r: 0,
            c: 0
        },
        e: {
            r: 0,
            c: 3
        }
    },
    {
        s: {
            r: 1,
            c: 0
        },
        e: {
            r: 1,
            c: 3
        }
    },
    {
        s: {
            r: 2,
            c: 0
        },
        e: {
            r: 2,
            c: 3
        }
    },


    //Vị trí
    {
        s: {
            r: 4,
            c: 0
        },
        e: {
            r: 4,
            c: 8
        }
    },
    // Ngày
    {
        s: {
            r: 5,
            c: 0
        },
        e: {
            r: 5,
            c: 8
        }
    }
];

const DYNAMIC_COLUMN_WIDTH_CONFIG = {
    wch: 6
}

const COLUMN_WIDTH_INITIALIZE = [{
        wch: 6
    }, // STT
    {
        wch: 20
    }, // Chủ Phương Tiện
    {
        wch: 10
    }, // Đơn vị thu gom
    {
        wch: 7
    }, // Mã số
    {
        wch: 10
    }, // Biến kiểm soát
    {
        wch: 8
    }, // Loại phương tiện
    {
        wch: 11
    }, // Tải trọng(kg)
    {
        wch: 8
    }, // Số chuyến đăng ký
];


const headerBorderConfig = {
    top: {
        style: "thin",
        color: "rgb(0,0,0)"
    },
    bottom: {
        style: "thin",
        color: "rgb(0,0,0)"
    },
    left: {
        style: "thin",
        color: "rgb(0,0,0)"
    },
    right: {
        style: "thin",
        color: "rgb(0,0,0)"
    },
}

const dataBorderConfig = {
    top: {
        style: "dotted",
        color: "rgb(0,0,0)"
    },
    bottom: {
        style: "dotted",
        color: "rgb(0,0,0)"
    },
    left: {
        style: "thin",
        color: "rgb(0,0,0)"
    },
    right: {
        style: "thin",
        color: "rgb(0,0,0)"
    },
}

const dataCellConfig = {
    t: "s",
    s: {
        border: {
            ...dataBorderConfig
        },
        alignment: {
            horizontal: "center",
            vertical: "center"
        }
    }
}


const overLimitationCellConfig = {
    t: "s",
    s: {
        border: {
            ...dataBorderConfig
        },
        alignment: {
            horizontal: "center",
            vertical: "center"
        },
        fill: {
            fgColor: {
                rgb: "ffff96"
            }
        }
    }
}

const sundayCellConfig = {
    t: "s",
    s: {
        border: {
            ...dataBorderConfig
        },
        alignment: {
            horizontal: "center",
            vertical: "center"
        },
        fill: {
            fgColor: {
                rgb: "fcd5b4"
            }
        }
    }
}



const headerCellConfig = {
    t: "s",
    s: {
        alignment: {
            wrapText: true,
            horizontal: "center",
            vertical: "center"
        },
        border: {
            ...headerBorderConfig
        }
    }
}


const sundayHeaderCellConfig = {
    t: "s",
    s: {
        alignment: {
            wrapText: true,
            horizontal: "center",
            vertical: "center"
        },
        border: {
            ...headerBorderConfig
        },
        fill: {
            fgColor: {
                rgb: "fcd5b4"
            }
        }
    }
}


const totalCellConfig = {
    t: "s",
    s: {
        alignment: {
            wrapText: true,
            horizontal: "center",
            vertical: "center"
        },
        border: {
            ...headerBorderConfig
        },
        fill: {
            fgColor: {
                rgb: "538dd5"
            }
        },
        font: {
            color: {
                rgb: "ffffff"
            },
            sz: 13
        }
    }
}


const prefixFirstHeaderRow = [{
        ...headerCellConfig,
        v: "STT",
    },
    {
        ...headerCellConfig,
        v: "Chủ Phương Tiện"
    },
    {
        ...headerCellConfig,
        v: "Đơn vị thu gom"
    },
    {
        ...headerCellConfig,
        v: "Mã số",
        t: "s"
    },
    {
        ...headerCellConfig,
        v: "Biến kiểm soát"
    },
    {
        ...headerCellConfig,
        v: "Loại phương tiện"
    },
    {
        ...headerCellConfig,
        v: "Tải trọng KG/Chuyến"
    },
    {
        ...headerCellConfig,
        v: "Số\n chuyến\n đăng ký"
    }
]


const rightSideHeaders = [{
    ...totalCellConfig,
    v: "TỔNG"
}, {
    ...headerCellConfig,
    v: "Khu vực thu gom"
}, {
    ...headerCellConfig,
    v: "Ghi chú"
}];


const LEFT_COLUMN_LENGTH = prefixFirstHeaderRow.length;
const LAST_COLUMN_LENGTH = rightSideHeaders.length;
const ADDED_MINUTES = 30;
const DATAROW_STARTAT = 6;

function buildFirstLine(totalColumn) {
    let row = [{
        v: 'ỦY BAN NHÂN DÂN TP.HỒ CHÍ MINH',
        t: 's',
        s: {
            alignment: {
                horizontal: "center"
            },
            font: {
                name: "Times New Roman",
            }
        }
    }];

    const startAt = totalColumn - 1 //Index start at 0
        -
        2 // Back 2 column from the last column
        -
        7 // Length of merged 

    for (let index = 1; index < startAt; index++) {
        row.push({});
    }

    row.push({
        v: 'CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM',
        t: 's',
        s: {
            alignment: {
                horizontal: "center"
            },
            font: {
                name: "Times New Roman",
            }
        }
    });

    return row;
}

function buildSecondLine(totalColumn) {
    let row = [{
        v: 'CÔNG TY TNHH MỘT THÀNH VIÊN',
        t: 's',
        s: {
            alignment: {
                horizontal: "center"
            },
            font: {
                name: "Times New Roman",
                bold: true
            }
        }
    }];

    const startAt = totalColumn - 1 //Index start at 0
        -
        2 // Back 2 column from the last column
        -
        7 // Length of merged 

    for (let index = 1; index < startAt; index++) {
        row.push({});
    }

    row.push({
        v: 'Độc lập - Tự do - Hạnh phúc',
        t: 's',
        s: {
            alignment: {
                horizontal: "center"
            },
            font: {
                name: "Times New Roman",
                bold: true,
                underline: true
            }
        }
    });

    return row;
}

function buildThirdLine() {
    return [{
        v: 'MÔI TRƯỜNG ĐÔ THỊ TP.HCM',
        t: 's',
        s: {
            alignment: {
                horizontal: "center"
            },
            font: {
                bold: true,
                name: "Times New Roman",
                underline: true,
            }
        }
    }];
}


function buildTitleLine() {
    return [{
        v: 'TỔNG HỢP PHƯƠNG TIỆN THU GOM RÁC NHẬP RÁC VÀO ĐIỂM HẸN, TRẠM TRUNG CHUYỂN',
        t: 's',
        s: {
            alignment: {
                horizontal: "center"
            },
            font: {
                bold: true,
                name: "Times New Roman",
                sz: 14
            }
        }
    }];
}


function buildLocationRow(stationName) {
    return [{
        v: `Tên trạm trung chuyển, điểm hẹn: ${stationName}`,
        t: 's',
        s: {
            font: {
                name: "Times New Roman",
                sz: 14
            }
        }
    }];
}

function buildDateRow(fromDate, toDate) {
    const dateMomentObj = moment(fromDate);
    return [{
        v: `THÁNG ${dateMomentObj.format("MM")} NĂM ${dateMomentObj.format("YYYY")}`,
        t: 's',
        s: {
            font: {
                name: "Times New Roman",
                sz: 14
            }
        }
    }];
}



function parseVehicleHistoryToDataRow(no, vehicleHistory, dateSlots) {

    let dataRow = [{
            ...dataCellConfig,
            v: no,
        },
        {
            ...dataCellConfig,
            v: vehicleHistory.vehicleDriverName
        },
        {
            ...dataCellConfig,
            v: vehicleHistory.vehicleCollectionUnitShortName
        },
        {
            ...dataCellConfig,
            v: vehicleHistory.vehicleCitencoId
        },
        {
            ...dataCellConfig,
            v: vehicleHistory.vehicleLicensePlate
        },
        {
            ...dataCellConfig,
            v: vehicleHistory.vehicleType
        },
        {
            ...dataCellConfig,
            v: vehicleHistory.vehicleLoad
        },
        {
            ...dataCellConfig,
            v: vehicleHistory.dailyLimit
        }
    ];


    let sumTimes = 0;
    for (let index = 0; index < dateSlots.length; index++) {
        const dateKey = dateSlots[index].dateKey;
        const times = vehicleHistory.dateKeys[dateKey] ? vehicleHistory.dateKeys[dateKey] : 0;
        sumTimes += times;
        const isOverLimitation = times > vehicleHistory.dailyLimit;
        let cellStyle;
        if (isOverLimitation) {
            cellStyle = overLimitationCellConfig;
        } else if (dateSlots[index].isSunday) {
            cellStyle = sundayCellConfig;
        } else {
            cellStyle = dataCellConfig;
        }

        dataRow.push({
            ...cellStyle,
            v: times
        });
    }

    //Tổng
    dataRow.push({
        ...totalCellConfig,
        v: sumTimes,
    });

    //
    dataRow.push({
        v: vehicleHistory.vehicleVerifiedBy,
        t: "s",
        s: {
            border: {
                ...dataBorderConfig
            },
            alignment: {
                wrapText: true
            }
        }
    });

    //Ghi chú
    dataRow.push({
        v: "",
        t: "s",
        s: {
            border: {
                ...dataBorderConfig
            },
            alignment: {
                wrapText: true
            }
        }
    });

    return dataRow;
}


function buildMergeConfig(dynamicColumnLength, historyLength) {
    let mergeConfig = [...MERGE_CONFIG_INITIALIZE];

    const totalColumn = LEFT_COLUMN_LENGTH + LAST_COLUMN_LENGTH + dynamicColumnLength;


    //Merge cell for right section line
    const rightSectionColumnStartAt = totalColumn - 1 //Index start at 0
        -
        2 // Back 2 column from the last column
        -
        7 // Length of merged 

    mergeConfig.push({
        s: {
            r: 0,
            c: rightSectionColumnStartAt
        },
        e: {
            r: 0,
            c: rightSectionColumnStartAt + 7
        }
    });
    mergeConfig.push({
        s: {
            r: 1,
            c: rightSectionColumnStartAt
        },
        e: {
            r: 1,
            c: rightSectionColumnStartAt + 7
        }
    });


    // Merge cell for title
    mergeConfig.push({
        s: {
            r: 3,
            c: 0
        },
        e: {
            r: 3,
            c: totalColumn - 1
        }
    });


    // Merge cells for total row
    const totalRowIndex = DATAROW_STARTAT + historyLength + 1;
    mergeConfig.push({
        s: {
            r: totalRowIndex,
            c: 0
        },
        e: {
            r: totalRowIndex,
            c: LEFT_COLUMN_LENGTH - 1
        }
    });

    return mergeConfig;
}

function buildColumnWidthConfig(dynamicColumnLength) {
    // Width cho 8 columns đầu tiên
    let columnWidthConfig = [...COLUMN_WIDTH_INITIALIZE];

    // Width cho dynamic columns (ngày)
    for (let index = 0; index < dynamicColumnLength; index++) {
        columnWidthConfig.push(DYNAMIC_COLUMN_WIDTH_CONFIG);
    }

    // Tổng
    columnWidthConfig.push({
        wch: 7
    });

    // Khu vực thu gom
    columnWidthConfig.push({
        wch: 15
    });

    // Ghi chú
    columnWidthConfig.push({
        wch: 15
    });

    return columnWidthConfig;
}


function exportExcelFile(sheet, {
    fileName = `Báo cáo lượt đổ rác.xlsx`,
} = {}) {
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "Báo cáo");
    XLSX.writeFile(wb, fileName);
}


function buildTopSectionRows(aoaRows, dynamicColumnLength, stationName, fromDate, toDate) {
    const totalColumn = LEFT_COLUMN_LENGTH + LAST_COLUMN_LENGTH + dynamicColumnLength;
    aoaRows.push(buildFirstLine(totalColumn));
    aoaRows.push(buildSecondLine(totalColumn));
    aoaRows.push(buildThirdLine());
    aoaRows.push(buildTitleLine());
    aoaRows.push(buildLocationRow(stationName));
    aoaRows.push(buildDateRow(fromDate, toDate));
}


function buildDataHeaderRow(aoaRows, dateSlots) {

    let firstHeaderRow = [...prefixFirstHeaderRow];

    for (let index = 0; index < dateSlots.length; index++) {
        const style = dateSlots[index].isSunday ? sundayHeaderCellConfig : headerCellConfig;
        firstHeaderRow.push({
            ...style,
            v: `Ngày\n${moment(dateSlots[index].start).format("DD/MM")}`,
        });
    }

    firstHeaderRow = firstHeaderRow.concat([...rightSideHeaders]);

    aoaRows.push(firstHeaderRow);
}

function buildTotalRow(dateSlots) {
    let row = [];

    row.push({
        v: 'TỔNG',
        t: 's',
        s: {
            alignment: {
                wrapText: true,
                horizontal: "center",
                vertical: "center"
            },
            border: {
                ...headerBorderConfig
            },
            fill: {
                fgColor: {
                    rgb: "538dd5"
                }
            },
            font: {
                color: {
                    rgb: "ffffff"
                },
                name: "Times New Roman",
                bold: true,
                underline: true,
                sz: 13
            }
        }
    });


    for (let index = 1; index < LEFT_COLUMN_LENGTH; index++) {
        row.push({
            ...totalCellConfig,
            v: "",
        });
    }

    for (let index = 0; index < dateSlots.length; index++) {
        row.push({
            ...totalCellConfig,
            v: dateSlots[index].totalTimes,
        });
    }

    return row;
}


function getDateSlotsBetweenDateRange(fromDate, toDate, timeObj, duration) {
    fromDate = RemoveTimeFromDateObject(fromDate);
    toDate = RemoveTimeFromDateObject(toDate);

    let slots = [];
    //Add sai số 30 phút
    timeObj = AddMinutesToTimeObject(timeObj, -ADDED_MINUTES);
    duration = duration + 60;
    console.log(fromDate, toDate, duration, timeObj);
    do {
        let start = CombineDateObjectAndTimeObject(fromDate, timeObj);
        let end = new Date(start.getTime() + ConvertMinutesToMilliseconds(duration));

        slots.push({
            isSunday: start.getDay() === 0,
            start: start,
            end: end,
            dateKey: moment(start).format('YYYYMMDD'),
            startMiliseconds: start.getTime(),
            endMiliseconds: end.getTime(),
            totalTimes: 0
        });
        fromDate.setDate(fromDate.getDate() + 1);
    }
    while (fromDate <= toDate);


    return slots;
}

function getCleannedLicensePlate(licensePlate) {
    if (!licensePlate) {
        return "";
    }

    return licensePlate
        .replace(/\./g, "")
        .replace(/\s/g, "")
        .replace(/[-]/g, "");
}


function generateVehicleHistoryObj(history) {
    return {
        vehicleDriverName: history.vehicleDriverName || "",
        vehicleCollectionUnitShortName: history.vehicleCollectionUnitShortName || "",
        vehicleCitencoId: history.vehicleCitencoId || "",
        vehicleLicensePlate: history.vehicleLicensePlate || "",
        vehicleType: history.vehicleType || "",
        vehicleLoad: history.vehicleLoad || "",
        dailyLimit: history.dailyLimit || "",
        vehicleVerifiedBy: history.vehicleVerifiedBy || "",
        dateKeys: {}
    };
}


function enrichRegisteredHistoryObj(registeredObj, history, foundDateSlot) {
    if (!registeredObj[history.vehicleId]) {
        registeredObj[history.vehicleId] = generateVehicleHistoryObj(history);
    }

    IsNotAvailable(registeredObj[history.vehicleId].dateKeys[foundDateSlot.dateKey]) ?
        registeredObj[history.vehicleId].dateKeys[foundDateSlot.dateKey] = 1 :
        registeredObj[history.vehicleId].dateKeys[foundDateSlot.dateKey]++;

}


function enrichUnregisteredHistoryObj(unregisteredObj, history, foundDateSlot) {
    history.vehicleLicensePlate = getCleannedLicensePlate(history.vehicleLicensePlate);
    if (IsNotAvailable(unregisteredObj[history.vehicleLicensePlate])) {
        unregisteredObj[history.vehicleLicensePlate] = generateVehicleHistoryObj(history);
    }

    IsNotAvailable(unregisteredObj[history.vehicleLicensePlate].dateKeys[foundDateSlot.dateKey]) ?
        unregisteredObj[history.vehicleLicensePlate].dateKeys[foundDateSlot.dateKey] = 1 :
        unregisteredObj[history.vehicleLicensePlate].dateKeys[foundDateSlot.dateKey]++

}


function categorizeVehicleHistories(histories = [], dateSlots = []) {
    let registeredVehicleObj = {};
    let unregisteredVehicleObj = {};
    for (let index = 0; index < histories.length; index++) {
        const history = histories[index];
        let vehicleInStationAtMoment = moment(history.vehicleInStationAt);
        const vehicleInStationAtMiliseconds = vehicleInStationAtMoment.unix() * 1000;
        const foundDateSlot = dateSlots.find(slot => vehicleInStationAtMiliseconds >= slot.startMiliseconds && vehicleInStationAtMiliseconds <= slot.endMiliseconds);
        if (!foundDateSlot) {
            continue;
        }
        foundDateSlot.totalTimes++;

        IsNotAvailable(history.vehicleId) ?
            enrichUnregisteredHistoryObj(unregisteredVehicleObj, history, foundDateSlot) :
            enrichRegisteredHistoryObj(registeredVehicleObj, history, foundDateSlot);
    }

    const registeredVehicleHistories = Object.keys(registeredVehicleObj)
        .map((vehicleId) => registeredVehicleObj[vehicleId])
        .sort(vehicleCollectionUnitShortNameSortCompare);
    const unregisteredVehicleHistories = Object.keys(unregisteredVehicleObj).map((licensePlate) => unregisteredVehicleObj[licensePlate]);

    return registeredVehicleHistories.concat(unregisteredVehicleHistories);


    function vehicleCollectionUnitShortNameSortCompare(a, b) {
        if (a.vehicleCollectionUnitShortName < b.vehicleCollectionUnitShortName) {
            return -1;
        }
        if (a.vehicleCollectionUnitShortName > b.vehicleCollectionUnitShortName) {
            return 1;
        }
        return 0;
    }
}

export default function ExportTotalTimeVehicleTakeOnGarbageReport(histories = [], station = null, stationActiveTime, {
    fromDate = null,
    toDate = null
} = {}) {

    if (!fromDate || !toDate || !station) {
        alert("Error");
        return;
    }

    const activeTimeObj = {
        hour: stationActiveTime.hourStartAt,
        minute: stationActiveTime.minuteStartAt
    }
    const dateSlots = getDateSlotsBetweenDateRange(fromDate, toDate, activeTimeObj, stationActiveTime.minuteDuration);
    const vehicleHistories = categorizeVehicleHistories(histories, dateSlots);

    let aoaRows = [];
    const dynamicColumnLength = Math.max(15, dateSlots.length);
    buildTopSectionRows(aoaRows, dynamicColumnLength, station.stationName, fromDate, toDate);
    buildDataHeaderRow(aoaRows, dateSlots)


    for (let index = 0; index < vehicleHistories.length; index++) {
        aoaRows.push(parseVehicleHistoryToDataRow((index + 1), vehicleHistories[index], dateSlots));
    }

    aoaRows.push(buildTotalRow(dateSlots));

    var ws = XLSX.utils.aoa_to_sheet(aoaRows);
    ws["!merges"] = buildMergeConfig(dynamicColumnLength, vehicleHistories.length);
    ws["!cols"] = buildColumnWidthConfig(dateSlots.length);

    exportExcelFile(ws);
}