import {
    Stack,
    Paper,
    styled
} from '@mui/material'
import ModalImage from "react-modal-image";

const Item = styled(Paper)(({
    theme
}) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
}));


export default function Gallery(props) {
    return <Stack spacing = {2}>
                {
                    props.items.map((image) => (
                    <Item key={image.historyImageId}>
                            {/* <ModalImage
                                small={`${process.env.REACT_APP_API_DOMAIN}${image.imagePath}`}
                                large={`${process.env.REACT_APP_API_DOMAIN}${image.imagePath}`}
                                alt={
                                    image.historyImageId
                                }
                                loading="lazy"
                            /> */}
                        <img style={{maxWidth:"100%"}}
                            src = {`${process.env.REACT_APP_API_DOMAIN}${image.imagePath}`}
                            srcSet = {`${process.env.REACT_APP_API_DOMAIN}${image.imagePath}`}
                            loading="lazy"
                            alt = {
                                image.historyImageId
                            }
                        />
                    </Item>
                ))
            }
        </Stack>
}