import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton, GridToolbarDensitySelector
} from '@mui/x-data-grid';
import { memo } from 'react';
import './style.css';


const CustomToolbar = memo(() => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
});

const localTextTranslate = {
    toolbarColumns: "Cột",
    toolbarDensity: "Độ rộng dòng",
    toolbarDensityCompact: 'Nhỏ',
    toolbarDensityStandard: 'Trung Bình',
    toolbarDensityComfortable: 'Lớn',
    // toolbarExport: "Xuất file",
    // toolbarExportCSV: "Xuất file CSV",
    // toolbarExportPrint: "In",
    columnsPanelHideAllButton: "Ẩn hết cột",
    columnsPanelShowAllButton: "Hiện hết cột",
    columnsPanelTextFieldPlaceholder: "Tên cột",
    columnsPanelTextFieldLabel: "Tìm cột",
    MuiTablePagination: {
        labelRowsPerPage: "Số dòng mỗi trang"
    }
}

const setRowStyle = (params) => {
    if (params.row && params.row.isOverLimitation) {
        return 'red-row';
    }

    return '';
};


export default function DataGridDefault(props) {
    console.log(props.sortModel);
    return <DataGrid components={{ Toolbar: CustomToolbar }}
        disableColumnFilter={true}
        disableColumnMenu={true}
        localeText={localTextTranslate}
        getRowClassName={setRowStyle}
        // initialState={{
        //     sorting: {
        //         sortModel: [{ field: 'vehicleInStationAt', sort: 'asc' }],
        //     }
        // }}
        initialState={{
            sorting: {
                sortModel: props.sortBy ,
            }
        }}
        {...props}
    />
}