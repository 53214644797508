import axios from 'axios';
import {
    toast
} from 'react-toastify';
import auth from '../contexts/auth';

const request = axios.create({
    baseURL: `${process.env.REACT_APP_API_DOMAIN}/api/v1/`
});


function errorHandleWrapper(f) {
    return function() {
        const args = arguments;
        return new Promise((resolve, reject) => {
            f(...args)
                .then(function(result) {
                    resolve(result);
                })
                .catch(function(err) {
                    console.log(err);
                    switch (err.response.status) {
                        case 0: // Network
                            console.log(`Can't connect to server`);
                            toast.error('Không thể kết nối đến máy chủ', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            break;
                        case 401: // Unauthorized
                            window.location.href = '/signin'
                            console.log(`Error 401`);
                            break;
                        case 403: // Forbidden
                            toast.error("Bạn không có quyền sử dụng");
                            console.log(`Error 403`);
                            reject(err);
                            break;
                        case 404: // Not found
                            toast.error("Không tìm thấy nội dung");
                            console.log(`Error 404`);
                            break;
                        case 500: // Bad request
                            toast.error("Lỗi không xác định");
                            console.log(`Error 500`);
                            reject(err);
                            break;
                        default:
                            console.log(`Error 400`);
                            reject(err);
                            // throw err;
                    }
                });
        });
    }
}


export const get = errorHandleWrapper(async function(path, params = {}) {
    const response = await request.get(path, {
        params: params,
        headers: {
            'Authorization': `bearer ${auth.getToken()}`
        }
    });
    return response.data;
})


export const post = errorHandleWrapper(async function(path, params = {}) {
    const response = await request.post(path, params, {
        headers: {
            'Authorization': `Basic ${auth.getToken()}`
        }
    });
    return response.data;
});

export const put = errorHandleWrapper(async function(path, params = {}) {
    const response = await request.put(path, params, {
        headers: {
            'Authorization': `Basic ${auth.getToken()}`
        }
    });
    return response.data;
});


// export const del = errorHandleWrapper(async function (path, params = {}) {
//     const response = await request.delete(path, params, {
//         headers: {
//             'Authorization': `Basic ${auth.getToken()}`
//         }
//     });
//     return response.data;
// });

export default function apiRequest() {

};