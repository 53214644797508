

function GetImageBaseString64FromImageUrl(imageUrl, outputFormat) {

    return new Promise(function (resolve, reject) {
        try {
            var canvas = document.createElement('CANVAS');
            var ctx = canvas.getContext('2d');
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function () {
                canvas.height = img.height;
                canvas.width = img.width;
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL(outputFormat || 'image/jpg');
                //callback.call(this, dataURL);
                resolve(dataURL);
                // Clean up
                canvas = null;
            };

            img.onerror = function (err) {
                console.error(err);
                resolve(null);
            }

            img.src = imageUrl;
        } catch (err) {
            console.error(err);
            resolve(null);
        }
    });

}

export {
    GetImageBaseString64FromImageUrl
}