import { Box, Typography, Button, Modal, Fab, Grid } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useMemo, useState, useEffect, memo } from 'react';
import * as vehicleAPI from '../../contexts/vehicleAPI';
import IconButton from '@mui/material/IconButton';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import logo from '../../assests/images/logo.jpg';
import QrCodeWithLogo from "qrcode-with-logos";
import ImageIcon from '@mui/icons-material/Image';
import Gallery from '../../shareComponents/gallery.component';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const qrDemensionWidth = 300;
const qrDemensionHeight = 450;
const qrTextPaddingLeft = 10;
const logoHeight = 100;

const hideContainerCanvas = {
    position: "fixed",
    top: -999999,
    left: -999999
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '800px',
    minHeight: '400px',
    overflow: 'hidden',
};
export default function VehiclePage() {
    
    const [vehicles, setVehicles] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [images, setImages] = useState([]);
    const [selectedId, setSelectedId] = useState([]);

    const DownloadQRButton = memo(({ data, handleClick = () => { } }) => {
        return <IconButton onClick={() => { handleClick(data) }}>
            <DownloadForOfflineIcon />
        </IconButton>
    });

    const OpenGalleryButton = memo(({ data, handleClick = () => { } }) => {
        return <IconButton onClick={() => { handleClick(data) }}>
            <ImageIcon />
        </IconButton>
    });


    const handleCloseModal = function () {
        setOpenModal(false);
    }

    const columns = useMemo(()=>[
        {
            field: 'gallery', headerName: '', renderCell: params => <OpenGalleryButton data={params.row} handleClick={OpenGallery} />
        },

        {
            field: 'vehicleLicensePlate',
            headerName: 'Biển số',
            width: 150
        },

        { field: 'vehicleType', headerName: 'Loại xe', width: 120 },

        {
            field: 'vehicleLoad',
            headerName: 'Tải trọng (kg)',
            width: 150
        },

        {
            field: 'vehicleCollectionUnitName',
            headerName: 'Đã đăng ký đơn vị',
            width: 400
        },
        
        {
            field: 'registerstationName',
            headerName: 'Đã đăng ký trạm',
            width: 250,
            renderCell: params => `${params.row.registers.map(register => register.stationName).filter((value, index, self) => self.indexOf(value) === index).join(', ')}`
        },

        {
            field: 'actions', headerName: '', type: 'actions', renderCell: params => <DownloadQRButton data={params.row}handleClick={DownloadVehicleQRCode}/>
        }

    ], []);

    async function OpenGallery(row){
        console.log(row);
        setSelectedId(row.vehicleId);
        setOpenModal(true);
        await getVehicleImages(row.vehicleId);
    }

    async function getVehicleImages(vehicleId){
        const records = await vehicleAPI.getVehicleImages(vehicleId);
        setImages(records);
    }


    function DownloadVehicleQRCode(row) { 
        const canvas = document.getElementById('qr-canvas');

        let qrcode = new QrCodeWithLogo({
            canvas: canvas,
            content: `${row.vehicleId}`,
            width: 300,
            //   download: true,
            image: document.getElementById("image"),
            logo: {
                src: logo,
                logoSize: 0.2
            },
            nodeQrCodeOptions:{
                margin: 1.5,
            }
            
        });

        const canvasWithId = document.getElementById('qrcode-with-id');
        const ctx = canvasWithId.getContext('2d');
        ctx.clearRect(0, 0, qrDemensionWidth, qrDemensionHeight);
        const imgName = `${row.vehicleLicensePlate}`;
        const stationNameString = row.registers.map(register => register.stationShortName)
            .filter((value, index, self) => self.indexOf(value) === index)
            .join(', ');

        qrcode.toCanvas()
        .then(()=>{

            setTimeout(() => {

                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, qrDemensionWidth, qrDemensionHeight);
                let endPos = 0;
                endPos = DrawTitle(ctx, endPos);
                
                endPos = DrawQR(ctx, canvas, endPos);
                DrawId(ctx, row.vehicleId, endPos);

                endPos = DrawStationNames(ctx, stationNameString, endPos);
                endPos = DrawLicensePlate(ctx, row.vehicleLicensePlate, endPos);
                endPos = DrawCollectionUnits(ctx, row.vehicleCollectionUnitShortName, endPos);
                // DrawLoad(ctx, row.vehicleLoad, endPos);
                
                Download(canvasWithId, imgName);
            }, 300);

        });



        function DrawTitle(ctx, startAt) {
            startAt = startAt + 40;
            ctx.fillStyle = "red";
            ctx.font = "bold 35px Arial";
            ctx.textAlign = "center";
            ctx.fillText(`CITENCO`, 150, startAt);
            return startAt;
        }


        function DrawQR(ctx, canvas, startAt) {
            startAt = startAt;
            ctx.drawImage(canvas, 0, startAt, qrDemensionWidth, qrDemensionWidth);
            return startAt + qrDemensionWidth;
        }
        
      
        function DrawId(ctx, id, startPos) {
            startPos = startPos + 80;
            ctx.fillStyle = "#172273";
            ctx.font = "bold 110px Arial";
            ctx.textAlign = "right";
            ctx.fillText(`${id}`, 280, startPos);
            return startPos;
        }

        function DrawStationNames(ctx, title, startPos) {
            startPos = startPos + 20;
            ctx.fillStyle = "#172273";
            ctx.font = "bold 35px Arial";
            ctx.textAlign = "left";
            ctx.fillText(`${title}`, 20, startPos);
            return startPos;
        }


        function DrawCollectionUnits(ctx, str, startPos) {
            console.log(str, startPos);
            startPos = startPos + 30;
            ctx.fillStyle = "#172273";
            ctx.font = "bold 20px Arial";
            ctx.textAlign = "left";
            ctx.fillText(str, 20, startPos);
            return startPos;
        }

        function DrawLicensePlate(ctx, licensePlate, startPos) {
            startPos = startPos + 30;
            ctx.font = "bold 20px Arial";   
            ctx.textAlign = "left";
            ctx.fillText(`${licensePlate}`, 20, startPos);
            return startPos;
        }

        function DrawLoad(ctx, load, startPos) {
            startPos = startPos + 30;
            ctx.font = "bold 20px Arial";
            ctx.textAlign = "left";
            ctx.fillText(`${load} kg`, 20, startPos);
            return startPos;
        }

        function Download(canvas, fileName){
            let downloadLink = document.createElement('a');
            downloadLink.href = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');;
            downloadLink.download = `${fileName.trim()}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }

    }


    useEffect(()=>
    {
        const getVehicles = async function (){
            const vehicles = await vehicleAPI.getAll();
            setVehicles(vehicles);
        }

        getVehicles();
    }, []);

    function convertFileToBase64(file){
        return new Promise((resolve, reject)=>{
            var reader = new FileReader();
            reader.onload = function () {
                const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
                resolve(base64String);
            }
            reader.readAsDataURL(file);
        });
    }


    const imageUploadOnChange = function(e){
        const files = document.getElementById('image-upload').files;
        let fileHandleArr = [];
        for (let index = 0; index < files.length; index++) {
            fileHandleArr.push(convertFileToBase64(files[index]));
        }

        if (fileHandleArr.length == 0){
            return;
        }

        Promise.all(fileHandleArr)
        .then(async (values) => {
            await vehicleAPI.addVehicleImages(selectedId, values);
            await getVehicleImages(selectedId);
        });;
    }

    const localTextTranslate = {
        toolbarColumns: "Cột",
        toolbarDensity:"Độ rộng dòng",
        toolbarExport:"Xuất file",
        toolbarExportCSV:"Xuất file CSV",
        toolbarExportPrint:"In",
        columnsPanelHideAllButton:"Ẩn hết cột",
        columnsPanelShowAllButton: "Hiện hết cột",
    }

    return <Box sx={{
        height:700,
        width:'100%'
    }}>
        <Typography variant='h3' component='h3' sx={{ mt:3,mb:3 }}>
            Quản lý phương tiện
        </Typography>

        <DataGrid 
            columns={columns} 
            rows={vehicles} 
            getRowId={row => row.vehicleId} 
            components={{ Toolbar: GridToolbar }}
            disableColumnFilter={true}
            disableColumnMenu={true}  
            localeText={localTextTranslate}/>

        <div style={hideContainerCanvas} >
            <canvas id='qr-canvas' width={qrDemensionWidth} height={qrDemensionHeight} />
            <canvas id='qrcode-with-id' width={qrDemensionWidth} height={qrDemensionHeight} />
        </div>

        <Modal open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={modalStyle}>
                <Grid container justifyContent="space-between"
                    alignItems="center">
                    <Grid item xs={8}>
                        <Typography id="modal-modal-title" variant="h3" component="h3">
                            Hình
                        </Typography>
                    </Grid>
                    <Grid item xs={4} style={{textAlign:"right"}}>
                        <Fab color="primary" variant="extended" onClick={() => { document.getElementById('image-upload').click(); }} >
                            <CloudUploadIcon sx={{ mr: 1 }} />
                            <input id='image-upload' onChange={imageUploadOnChange} style={{ display: 'none' }} accept="image/*" type="file" multiple />
                            Upload
                        </Fab>
                    </Grid>
                </Grid>
                
                <Box style={{ overflow: 'auto', maxHeight: 700 }}>
                    <Gallery items={images} />
                </Box>
            </Box>  
        </Modal>
        
    </Box>
    
}