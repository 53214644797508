

import { useState } from 'react';
import * as vehicleAPI from '../../../contexts/vehicleAPI'
import {
    Box,
    Typography,
    Grid,
    Button,
    TextField,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';

import {
    toast
} from 'react-toastify';

import {
    ParseTimeStringToTimeJson,
    ParseDateStringToDateJson,
    moment
} from '../../../utilities/dateAndTime';

import * as NumberHelper from '../../../utilities/number';

import * as historyAPI from '../../../contexts/historyAPI';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '800px',
    overflow: 'hidden',
};

function IsError(error) {
    return !error ? false : true;
}

const UnRegisteredVehicleInfo = ({ errorStates }) => {

    return <>
        <Grid item xs={3}>
            <div className="form-group">
                <InputLabel >Biển số</InputLabel>
                <TextField
                    required
                    name="vehicleLicensePlate"
                    defaultValue=""
                    error={IsError(errorStates.vehicleLicensePlate)}
                    helperText={errorStates.vehicleLicensePlate} />
            </div>
        </Grid>

        <Grid item xs={3}>
            <div className="form-group">
                <InputLabel >Tài xế</InputLabel>
                <TextField
                    required
                    name="vehicleDriverName"
                    defaultValue=""
                    error={IsError(errorStates.vehicleDriverName)}
                    helperText={errorStates.vehicleDriverName} />
            </div>
        </Grid>

        <Grid item xs={3}>
            <div className="form-group">
                <InputLabel >Loại xe</InputLabel>
                <TextField
                    required
                    name="vehicleType"
                    defaultValue=""
                    error={IsError(errorStates.vehicleType)}
                    helperText={errorStates.vehicleType} />
            </div>
        </Grid>

        <Grid item xs={3}>
            <div className="form-group">
                <InputLabel >Khối lượng</InputLabel>
                <TextField
                    required
                    name="vehicleLoad"
                    defaultValue=""
                    error={IsError(errorStates.vehicleLoad)}
                    helperText={errorStates.vehicleLoad} />
            </div>
        </Grid>
    </>
}


const RegisteredVehicleInfo = ({ handleLicensePlateChanged, history }) => {

    const vehicleLicensePlateChanged = async (event) => {
        handleLicensePlateChanged(event.target.value);
    }

    return <>
        <Grid item xs={3}>
            <div className="form-group">
                <InputLabel >Biển số</InputLabel>
                <TextField
                    required
                    name="vehicleLicensePlate"
                    onBlur={vehicleLicensePlateChanged}
                    defaultValue={""}
                    error={!history.vehicleId}
                />
            </div>
        </Grid>

        <Grid item xs={3}>
            <div className="form-group">
                <InputLabel >Tài xế</InputLabel>
                {
                    history.actionType === 1
                        ? <TextField
                            required
                            name="vehicleDriverName"
                            defaultValue="" />
                        : <p>{history.vehicleDriverName}</p>
                }

            </div>
        </Grid>

        <Grid item xs={3}>
            <div className="form-group">
                <InputLabel >Loại xe</InputLabel>
                <p>{history.vehicleType}</p>
            </div>
        </Grid>

        <Grid item xs={3}>
            <div className="form-group">
                <InputLabel >Khối lượng</InputLabel>
                <p>{history.vehicleLoad}</p>
            </div>
        </Grid>


    </>
}


const HistoryTypeItemMap = {
    0: [{
        value: "registered",
        label: "Xe đã đăng ký"
    },
    {
        value: "unregistered",
        label: "Xe vãng lai"
    }],
    1: [{
        value: "registered",
        label: "Xe đã đăng ký"
    }],
}

const GetHistoryTypes = (actionType) => HistoryTypeItemMap[actionType] || [];

export default function NewHistory({ actionType, stations = [], onClose = () => { }, onAfterDone = () => { } }) {

    const [history, setHistory] = useState({
        type: "registered",
        actionType: actionType,
        vehicleLicensePlate: "",
        vehicleId: null,
        stationId: stations.length === 0 ? null : stations[0].stationId
    });

    const [errorStates, setErrorStates] = useState({});

    const getDateTimeJson = (dateString, timeString) => {
        const dateJson = ParseDateStringToDateJson(dateString);
        const timeJson = ParseTimeStringToTimeJson(timeString);
        if (!dateJson || !timeJson) {
            return null;
        }

        return {
            ...dateJson,
            ...timeJson
        }
    }

    const validNewHistoryData = (newHistoryData) => {
        let errors = {};

        if (newHistoryData.type === "registered" && !newHistoryData.vehicleId) {
            errors.vehicleId = "Phương tiện không tồn tại";
        }

        if (newHistoryData.type === "unregistered") {
            if (!newHistoryData.vehicleLicensePlate) {
                errors.vehicleLicensePlate = "Biển số không được bỏ trống";
            }

            if (!newHistoryData.vehicleLoad) {
                errors.vehicleLoad = "Khối lượng không được bỏ trống";
            } else if (!NumberHelper.IsNumber(newHistoryData.vehicleLoad)) {
                errors.vehicleLoad = "Khối lượng không hợp lệ";
            }
        }

        if (!newHistoryData.stationId) {
            errors.stationId = "Không hợp lệ";
        }

        if (!newHistoryData.inMoment) {
            errors.inMoment = "Không hợp lệ";
        }

        if (!newHistoryData.outMoment) {
            errors.outMoment = "Không hợp lệ";
        }

        if (newHistoryData.inMoment
            && newHistoryData.outMoment
            && moment(newHistoryData.inMoment) > moment(newHistoryData.outMoment)) {
            errors.outMoment = "Thời gian vào phải lớn hơn thời gian ra";
        }

        return errors;
    }

    const toggleButtons = (buttons, disabled = true) => buttons.forEach(button => button.disabled = disabled);

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const formElement = event.currentTarget;
        const formData = new FormData(formElement);
        const buttons = formElement.querySelectorAll(".form-button");
        const newHistoryData = {
            ...history,
            inMoment: getDateTimeJson(formData.get('inDate'), formData.get('inTime')),
            outMoment: getDateTimeJson(formData.get('outDate'), formData.get('outTime')),
            vehicleDriverName: formData.get('vehicleDriverName') ?? "",
        }

        if (newHistoryData.type === "unregistered") {
            newHistoryData.vehicleLicensePlate = formData.get('vehicleLicensePlate').trim();
            newHistoryData.vehicleLoad = formData.get('vehicleLoad').trim();
            newHistoryData.vehicleType = formData.get('vehicleType').trim();
        }

        const errors = validNewHistoryData(newHistoryData);
        setErrorStates(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }

        toggleButtons(buttons, true);

        try {
            await historyAPI.createMissedHistory(newHistoryData);
            toast.success("Thành công");
            onAfterDone();
        } catch (error) {
            toast.error(error.description);
            toggleButtons(buttons, false);
        }

    }


    const loadVehicleInfo = async (licensePlate) => {
        if (history.vehicleLicensePlate === licensePlate) return;

        try {

            const vehicle = await vehicleAPI.find({
                licensePlate: licensePlate
            });

            if (vehicle === null) {

                setHistory({
                    ...history,
                    vehicleLicensePlate: licensePlate,
                    vehicleType: "",
                    vehicleDriverName: "",
                    vehicleLoad: "",
                    vehicleId: null
                });

                return;
            }

            setHistory({
                ...history,
                vehicleLicensePlate: licensePlate,
                vehicleType: vehicle.vehicleType || "",
                vehicleDriverName: vehicle.vehicleDriverName || "",
                vehicleLoad: vehicle.vehicleLoad || "",
                vehicleId: vehicle.vehicleId || null
            });

        } catch (err) {
            toast.error(err.description)
        }
    }

    const historyTypeChanged = async (event) => {
        setHistory({
            ...history,
            vehicleLicensePlate: "",
            vehicleType: "",
            vehicleDriverName: "",
            vehicleLoad: "",
            vehicleId: null,
            type: event.target.value
        });
    }


    return <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h4" component="h4">
            Tạo lịch sử
        </Typography>

        <br />

        <Box style={{ overflow: 'auto', maxHeight: 700 }} component="form"
            noValidate
            onSubmit={handleFormSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <InputLabel >Loại</InputLabel>
                    <Select value={history.type}
                        onChange={historyTypeChanged}>
                        {
                            GetHistoryTypes(history.actionType)
                                .map(item => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)
                        }
                    </Select>
                </Grid>

                <Grid item xs={9}>
                    <InputLabel >Hình thức</InputLabel>
                    <p><b>{actionType === 1 ? "LẤY RÁC" : "ĐỔ RÁC"}</b></p>
                </Grid>

                {
                    history.type === "registered"
                        ? <RegisteredVehicleInfo handleLicensePlateChanged={loadVehicleInfo} history={history} />
                        : <UnRegisteredVehicleInfo errorStates={errorStates} />
                }

                <Grid item xs={12}>
                    <InputLabel >Trạm</InputLabel>
                    <Select fullWidth value={history.stationId}
                        error={IsError(errorStates.stationId)}
                        onChange={(event) => { setHistory({ ...history, stationId: event.target.value }) }}>
                        {
                            stations.map(station => <MenuItem key={station.stationId} value={station.stationId}>{station.stationName}</MenuItem>)
                        }
                    </Select>
                </Grid>

                <Grid item xs={6}>
                    <div className="form-group">
                        <InputLabel >Vào trạm lúc(Ngày/tháng/năm)</InputLabel>
                        <Grid container columnSpacing={1}>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    fullWidth
                                    name="inDate"
                                    defaultValue=""
                                    placeholder='31/12/2000'
                                    error={IsError(errorStates.inMoment)}
                                    helperText={errorStates.inMoment}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    fullWidth
                                    name="inTime"
                                    defaultValue=""
                                    placeholder='00:00'
                                    error={IsError(errorStates.inMoment)}
                                />
                            </Grid>
                        </Grid>

                    </div>
                </Grid>

                <Grid item xs={6}>
                    <div className="form-group">
                        <InputLabel >Ra trạm lúc(Ngày/tháng/năm)</InputLabel>
                        <Grid container columnSpacing={1}>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    fullWidth
                                    name="outDate"
                                    defaultValue={""}
                                    placeholder='31/12/2000'
                                    error={IsError(errorStates.outMoment)}
                                    helperText={errorStates.outMoment}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    fullWidth
                                    name="outTime"
                                    defaultValue={""}
                                    placeholder='00:00'
                                    error={IsError(errorStates.outMoment)}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="form-group" style={{ textAlign: 'right' }}>
                        <Button
                            onClick={onClose}
                            type="button"
                            size="large"
                            className='form-button'
                            style={{ marginTop: 12 }}>Đóng</Button>

                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            className='form-button'
                            style={{ marginTop: 12, marginLeft: 16 }}
                        >Tạo</Button>
                    </div>
                </Grid>

            </Grid>
        </Box>
    </Box>

}