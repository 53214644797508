import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HistoryIcon from '@mui/icons-material/History';
import Link from '@mui/material/Link';
import LogoutIcon from '@mui/icons-material/Logout';
import auth from '../contexts/auth';
// import { useNavigate } from "react-router-dom"
 import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
// import DashboardIcon from '@mui/icons-material/Dashboard';

export default function LeftMenu(){
    function Logout(){
        auth.clear();
    }

    return <React.Fragment>
        
        {/* <Link href="/main/dashboard" underline="none" color="primary">
            <ListItemButton>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItemButton>
        </Link> */}
        
        <Link href="/main/history" underline="none" color="primary">
            <ListItemButton>
                <ListItemIcon>
                    <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary="Lịch sử đổ rác" />
            </ListItemButton>
        </Link>

        <Link href="/main/takeOutGarbageHistory" underline="none" color="primary">
            <ListItemButton>
                <ListItemIcon>
                    <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary="Lịch sử lấy rác" />
            </ListItemButton>
        </Link>

        {/* <Link href="/main/vehicles" underline="none" color="primary">
            <ListItemButton>
                <ListItemIcon>
                    <EmojiTransportationIcon />
                </ListItemIcon>
                <ListItemText primary="Quản lý phương tiện" />
            </ListItemButton>
        </Link> */}

        <Link href="/signin" onClick={Logout} underline="none" color="primary">
            <ListItemButton>
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Đăng xuất" />
            </ListItemButton>
        </Link>
    </React.Fragment>
}

