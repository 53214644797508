import * as request from './request';
import {
    ApiErrorHandleWrapper
} from './errorHandle';


export const searchHistories = ApiErrorHandleWrapper(async function(params) {
    const response = await request.get('/histories', params);
    return response;
});


export const getTakeOnGarbageHistories = ApiErrorHandleWrapper(async function(params) {
    const response = await request.get('/histories/takeOnGarbage', params);
    return response;
});

export const getTakeOutGarbageHistories = ApiErrorHandleWrapper(async function(params) {
    const response = await request.get('/histories/takeOutGarbage', params);
    return response;
});

export const getHistoryImages = ApiErrorHandleWrapper(async function getAll(historyId) {
    const response = await request.get(`/histories/${historyId}/images`);
    return response;
});

export const updateTakeOutGarbageWeight = ApiErrorHandleWrapper(async function(params) {
    const response = await request.put('/histories/takeOutGarbageWeight', params);
    return response;
});


export const updateHistoryOutStationTime = ApiErrorHandleWrapper(async function(params) {
    const response = await request.put('/histories/outStationDateTime', params);
    return response;
});


export const createMissedHistory = ApiErrorHandleWrapper(async function(params) {
    const response = await request.post('/histories/missedHistories', params);
    return response;
});