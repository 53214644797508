import {
    Box,
    Button,
    TextField,
    InputLabel,
    Grid,
    Typography
} from '@mui/material';

import {
    RemoveSecondFromTimeString,
    IsValidTimeString,
    CorrectTimeFormat,
    ParseDateStringToDateJson
} from '../../utilities/dateAndTime';
import * as numberHelper from '../../utilities/number';
import { useState } from 'react'
import {
    toast
} from 'react-toastify';

import * as historyAPI from '../../contexts/historyAPI';
import moment from 'moment';
import { DateTimeFormat } from '../../utilities/moment';

const editTextFieldStyle = {
    width: '100%',
}




export default function UpdateHistoryWeightComponent({ onAfterSuccess, onClose, history, sxStyle }) {

    const [errorStates, setErrorStates] = useState({});


    function getHistoryWeightJsonFromFormElement(formElement) {
        const formData = new FormData(formElement);
        const inWeight = numberHelper.TonToKilo(formData.get('comeInVehicleWeight').trim());
        const outWeight = numberHelper.TonToKilo(formData.get('exitVehicleWeight').trim());
        const garbageWeight = inWeight - outWeight;
        return {
            historyId: formData.get('historyId').trim(),
            vehicleDriverName: formData.get('vehicleDriverName').trim(),
            weightCertificateCode: formData.get('weightCertificateCode').trim(),
            comeInWeightStationAt: CorrectTimeFormat(formData.get('comeInWeightStationAt').trim()),
            exitWeightStationAt: CorrectTimeFormat(formData.get('exitWeightStationAt').trim()),
            comeInVehicleWeight: inWeight,
            exitVehicleWeight: outWeight,
            garbageWeight,
            vehicleInWeightStationOnDate: ParseDateStringToDateJson(formData.get('vehicleInWeightStationOnDate').trim()),
        };
    }


    function validateHistoryGarbageWeight(historyGarbageWeight) {

        let errors = {};
        if (!historyGarbageWeight.vehicleDriverName) {
            errors.vehicleDriverName = "Không được bỏ trống"
        }

        if (historyGarbageWeight.weightCertificateCode
            || historyGarbageWeight.comeInWeightStationAt
            || historyGarbageWeight.exitWeightStationAt
            || historyGarbageWeight.comeInVehicleWeight
            || historyGarbageWeight.exitVehicleWeight
            || historyGarbageWeight.garbageWeight
            || historyGarbageWeight.vehicleInWeightStationOnDate) {

            if (!historyGarbageWeight.weightCertificateCode) {
                errors.weightCertificateCode = "Không được bỏ trống";
            } else if (historyGarbageWeight.weightCertificateCode.length > 30) {
                errors.weightCertificateCode = "Không được dài quá 30 ký tự";
            }

            if (!historyGarbageWeight.comeInWeightStationAt || !IsValidTimeString(historyGarbageWeight.comeInWeightStationAt)) {
                errors.comeInWeightStationAt = "Thời gian không hợp lệ";
            }

            if (!historyGarbageWeight.exitWeightStationAt || !IsValidTimeString(historyGarbageWeight.exitWeightStationAt)) {
                errors.exitWeightStationAt = "Thời gian không hợp lệ";
            }

            if (!historyGarbageWeight.comeInVehicleWeight
                || isNaN(historyGarbageWeight.comeInVehicleWeight)
                || historyGarbageWeight.comeInVehicleWeight < 0) {
                errors.comeInVehicleWeight = "Trọng lượng không hợp lệ"
            }

            if (!historyGarbageWeight.exitVehicleWeight
                || isNaN(historyGarbageWeight.exitVehicleWeight)
                || historyGarbageWeight.exitVehicleWeight < 0) {
                errors.exitVehicleWeight = "Trọng lượng không hợp lệ"
            } else if (historyGarbageWeight.exitVehicleWeight > historyGarbageWeight.comeInVehicleWeight) {
                errors.exitVehicleWeight = "Trọng lượng ra nên nhỏ hơn trọng lượng vào"
            }

            if (!historyGarbageWeight.vehicleInWeightStationOnDate) {
                errors.vehicleInWeightStationOnDate = "Ngày không hợp lệ";
            }
        }

        return errors;
    }

    const toggleDisableButtons = (buttons, status) => {
        if (!buttons || buttons.length === 0) {
            return;
        }
        buttons.forEach(btn => btn.disabled = status);
    }

    async function _onSubmit(event) {
        event.preventDefault();
        const form = event.currentTarget;
        const newGarbageWeightInfo = getHistoryWeightJsonFromFormElement(form);
        const errors = validateHistoryGarbageWeight(newGarbageWeightInfo);
        setErrorStates(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }

        const buttons = form.querySelectorAll('.form-btn');
        toggleDisableButtons(buttons, true);
        try {
            await historyAPI.updateTakeOutGarbageWeight(newGarbageWeightInfo);
            toast.success("Cập nhật thành công");
            onAfterSuccess(newGarbageWeightInfo);
            toggleDisableButtons(buttons, false);
        } catch (err) {
            toast.error(err.description);
            toggleDisableButtons(buttons, false);
        }

    }


    function IsError(error) {
        return !error ? false : true;
    }

    function parseDateStringToCorrectDateFormat(dateString) {
        if (!dateString) {
            return "";
        }

        return moment(dateString).format("DD/MM/YYYY");
    }

    return <Box sx={sxStyle}>

        <Typography id="modal-modal-title" variant="h3" component="h3">
            Thông tin trạm cân
        </Typography>

        <hr />

        <Box id="update_history_weight_form"
            style={{ overflow: 'auto', maxHeight: 700 }}
            component="form"
            noValidate
            onSubmit={_onSubmit}>
            <input name="historyId" type="hidden" value={history.historyId} />
            <Grid container spacing={1}>

                <Grid item xs={4}>
                    <div className="form-group">
                        <InputLabel>Biến số</InputLabel>
                        <span>{history.vehicleLicensePlate}</span>
                    </div>
                </Grid>

                <Grid item xs={4}>
                    <div className="form-group">
                        <InputLabel>Vào trạm lúc</InputLabel>
                        <span>{history.vehicleInStationAt && DateTimeFormat(history.vehicleInStationAt, "YYYY-MM-DD HH:mm")}</span>
                    </div>
                </Grid>

                <Grid item xs={4}>
                    <div className="form-group">
                        <InputLabel>Rời trạm lúc</InputLabel>
                        <span>{history.vehicleOutStationAt && DateTimeFormat(history.vehicleOutStationAt, "YYYY-MM-DD HH:mm")}</span>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="form-group">
                        <InputLabel>Tên tài xế</InputLabel>
                        <TextField
                            required
                            name="vehicleDriverName"
                            style={editTextFieldStyle}
                            defaultValue={history.vehicleDriverName || ""}
                            error={IsError(errorStates.vehicleDriverName)}
                            helperText={errorStates.vehicleDriverName}
                        />
                    </div>
                </Grid>

                <Grid item xs={6}>
                    <InputLabel>Trọng lượng xe vào (tấn)</InputLabel>
                    <Grid container alignItems="center">
                        <TextField
                            required
                            type="number"
                            name="comeInVehicleWeight"
                            style={editTextFieldStyle}
                            defaultValue={numberHelper.KilogramsToTon(history.comeInVehicleWeight)}
                            error={IsError(errorStates.comeInVehicleWeight)}
                            helperText={errorStates.comeInVehicleWeight}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <InputLabel >Trọng lượng xe ra (tấn)</InputLabel>
                    <Grid container alignItems="center">
                        <TextField
                            required
                            type="number"
                            name="exitVehicleWeight"
                            style={editTextFieldStyle}
                            defaultValue={numberHelper.KilogramsToTon(history.exitVehicleWeight)}
                            error={IsError(errorStates.exitVehicleWeight)}
                            helperText={errorStates.exitVehicleWeight}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <div className="form-group" >
                        <InputLabel >Ra trạm cân ngày (ngày/tháng/năm)</InputLabel>
                        <TextField
                            required
                            name="vehicleInWeightStationOnDate"
                            style={editTextFieldStyle}
                            defaultValue={parseDateStringToCorrectDateFormat(history.vehicleInWeightStationOnDate)}
                            placeholder="30/12/2000"
                            error={IsError(errorStates.vehicleInWeightStationOnDate)}
                            helperText={errorStates.vehicleInWeightStationOnDate}
                        />
                    </div>
                </Grid>

                <Grid item xs={3}>
                    <div className="form-group" >
                        <InputLabel >Vào trạm cân lúc</InputLabel>
                        <TextField
                            required
                            name="comeInWeightStationAt"
                            style={editTextFieldStyle}
                            defaultValue={RemoveSecondFromTimeString(history.comeInWeightStationAt)}
                            placeholder="00:00"
                            error={IsError(errorStates.comeInWeightStationAt)}
                            helperText={errorStates.comeInWeightStationAt}
                        />
                    </div>
                </Grid>


                <Grid item xs={3}>
                    <div className="form-group">
                        <InputLabel >Ra trạm cân lúc</InputLabel>
                        <TextField
                            required
                            name="exitWeightStationAt"
                            style={editTextFieldStyle}
                            defaultValue={RemoveSecondFromTimeString(history.exitWeightStationAt)}
                            placeholder="00:00"
                            error={IsError(errorStates.exitWeightStationAt)}
                            helperText={errorStates.exitWeightStationAt}
                        />
                    </div>
                </Grid>

                <Grid item xs={4}>
                    <div className="form-group">
                        <InputLabel >Mã phiếu cân</InputLabel>
                        <TextField
                            required
                            name="weightCertificateCode"
                            style={editTextFieldStyle}
                            defaultValue={history.weightCertificateCode || ""}
                            error={IsError(errorStates.weightCertificateCode)}
                            helperText={errorStates.weightCertificateCode}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="form-group" style={{ textAlign: 'right' }}>
                        <Button
                            onClick={onClose}
                            type="button"
                            size="large"
                            style={{ marginTop: 12 }}
                            className='form-btn'
                        >Đóng</Button>

                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            style={{ marginTop: 12, marginLeft: 16 }}
                            className='form-btn'
                        >Cập nhật</Button>
                    </div>
                </Grid>
            </Grid>
        </Box>




    </Box>
}