import DP from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const days = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7']
const months = ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12']

const locale = {
    localize: {
        day: n => days[n],
        month: n => months[n]
    },
    formatLong: {
        date: () => 'dd/MM/yyyy',
        dateTime: () => 'dd/MM/yyyy HH:mm',
        time: () => 'HH:mm'
    }
}


export default function DatePicker({selected = null , onChange = () => {} }) {
    return <DP selected={selected}
                    onChange={onChange}
                    showTimeSelect
                    dateFormat="Pp"
                    locale={locale} />
}