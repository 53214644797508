export const ApiErrorHandleWrapper = function (f) {
    return function () {
        const args = arguments;
        return new Promise((resolve, reject) => {
            f(...args)
                .then(function (result) {
                    resolve(result.data);
                })
                .catch(function (err) {
                    console.log(err);
                    reject(err.response.data);
                });

        });
    }
}
