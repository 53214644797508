import {
    Box,
    Typography,
    Grid,
    Button
} from '@mui/material';
import Gallery from '../../shareComponents/gallery.component';



export default function ImageModalContent({ sxStyle, images, onClose }) {
    return <Box sx={sxStyle}>
        <Typography id="modal-modal-title" variant="h3" component="h3">
            Hình
        </Typography>

        <hr />

        <Box style={{ overflow: 'auto', maxHeight: 700 }}>
            <Gallery items={images} />
        </Box>

        <Grid container >
            <Grid item xs={12}>
                <div className="form-group" style={{ textAlign: 'right' }}>
                    <Button
                        onClick={onClose}
                        type="button"
                        size="large"
                        className='form-button'
                        style={{ marginTop: 12 }}>Đóng</Button>
                </div>
            </Grid>
        </Grid>
    </Box>
}