import moment from 'moment-timezone';
moment.tz.setDefault("Asia/Ho_Chi_Minh");

function DateTimeIsValid(dateTimeJsonObject) {
    return moment(dateTimeJsonObject).isValid();
}

function DateTimeFormat(date, format) {
    return moment(date).format(format);
}

function ConvertDateTimeToMillisecondTimeStamp(dateTime) {
    const momentObj = moment(dateTime);
    if (!momentObj.isValid()) {
        return null;
    }

    return momentObj.valueOf();
}



function CombineDateObjectAndTimeObject(date, timeObj) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), timeObj.hour, timeObj.minute, 0);
}



function ConvertHoursToMilliseconds(hours) {
    return hours * 60 * 60 * 1000;
}

function ConvertMinutesToMilliseconds(minutes) {
    return minutes * 60 * 1000;
}


function AddMinutesToTimeObject(timeObj, addedMinutes) {
    let date = new Date(2000, 5, 5, timeObj.hour, timeObj.minute, 0);
    date = new Date(date.getTime() + addedMinutes * 60000);
    return {
        hour: date.getHours(),
        minute: date.getMinutes()
    }
}


function RemoveTimeFromDateObject(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
}


function RemoveSecondFromTimeString(timeString) {
    if (!timeString) {
        return '';
    }

    let [hour, minute] = timeString.split(':');
    return `${hour}:${minute}`;
}


function CorrectTimeFormat(timeStr) {
    if (!timeStr) {
        return null;
    }

    const timeParts = timeStr.split(':');
    if (timeParts.length === 2) {
        return timeStr;
    }

    if (timeParts.length === 1) {
        return `${timeStr}:00`;
    }

    return null;
}

function ParseDateStringToDateJson(dateString, separator = '/') {
    if (!dateString) {
        return null;
    }

    let [date, months, years] = dateString.trim().split(separator);
    if (!date ||
        !months ||
        !years ||
        date.trim() === '' ||
        months.trim() === '' ||
        years.trim() === '') {
        return null;
    }

    months = parseInt(months) - 1;
    date = parseInt(date);
    years = parseInt(years);

    if (isNaN(months) ||
        isNaN(date) ||
        isNaN(years) ||
        years < 1000) {
        return null;
    }

    const dateJson = {
        date,
        months,
        years
    };

    if (!moment(dateJson).isValid()) {
        return null;
    }

    return dateJson;
}


function IsValidDateString(dateString, separator = '/') {
    if (!dateString) {
        return false;
    }

    let [date, months, years] = dateString.split(separator);
    if (!date ||
        !months ||
        !years ||
        date.trim() === '' ||
        months.trim() === '' ||
        years.trim() === '') {
        return false;
    }

    months = parseInt(months) - 1;
    date = parseInt(date);
    years = parseInt(years);

    if (isNaN(months) ||
        isNaN(date) ||
        isNaN(years)) {
        return false;
    }

    if (!moment({
            years,
            months,
            date
        }).isValid()) {
        return false;
    }

    return true;
}


function ParseTimeStringToTimeJson(timeString) {
    if (!timeString) {
        return null;
    }

    let [hour, minute] = timeString.trim().split(':');
    if (!hour ||
        !minute ||
        hour.trim() === '' ||
        minute.trim() === '') {
        return null;
    }

    hour = parseInt(hour);
    minute = parseInt(minute);

    if (isNaN(hour) || isNaN(minute)) {
        return null;
    }

    if (hour < 0 || hour > 25 || minute < 0 || minute > 59) {
        return null;
    }

    return {
        hour,
        minute
    };
}


function IsValidTimeString(timeString) {
    if (!timeString) {
        return false;
    }

    let [hour, minute] = timeString.split(':');
    if (!hour || !minute ||
        hour.trim() === '' || minute.trim() === '') {
        return false;
    }

    hour = parseInt(hour);
    minute = parseInt(minute);

    if (isNaN(hour) || isNaN(minute)) {
        return false;
    }

    if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
        return false;
    }

    return true;
}



export {
    moment,
    ParseTimeStringToTimeJson,
    CombineDateObjectAndTimeObject,
    ConvertHoursToMilliseconds,
    AddMinutesToTimeObject,
    ConvertMinutesToMilliseconds,
    RemoveTimeFromDateObject,
    RemoveSecondFromTimeString,
    IsValidTimeString,
    CorrectTimeFormat,
    IsValidDateString,
    DateTimeFormat,
    DateTimeIsValid,
    ConvertDateTimeToMillisecondTimeStamp,
    ParseDateStringToDateJson
}