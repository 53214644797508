import * as XLSX from 'xlsx-js-style';
import { moment } from '../../../utilities/moment'
import * as dateTimeHelper from '../../../utilities/dateAndTime';
import {
    KilogramsToTon,
    FormatFloat,
    ParseToFloat
} from '../../../utilities/number';
import {
    ConvertToName
} from '../../../utilities/string';
//Ngày	 
//Điểm đổ 	
//Số xe	
//Tài xế	
//Loại xe 	
//Chuyến	
//Trọng L vào	 
//Trọng L ra	 
//KL thực chạy	: Trọng L vào - Trọng L ra	
//KLCP : Khối lương * 1.1	
//KLNT : Min (KLCP, KL thực chạy )	
//KL Đã Cắt : KLNT - KL thực chạy	
//Cự ly	: 29.95
//TKm thực chạy	:  Cự ly * KL thực chạy
//TKm Nghiệm thu :  Cự ly * KLNT
//Giờ xuất bến	: Vào trạm + 30p
//Giờ vào	
//Giờ ra	
//Ngày Đêm : 6h - 22h là ngày
//Số phiếu TH	
//Số phiếu cân	
//Chi nhánh

const borderCellConfig = {
    top: {
        style: "thin",
        color: "rgb(0,0,0)"
    },
    bottom: {
        style: "thin",
        color: "rgb(0,0,0)"
    },
    left: {
        style: "thin",
        color: "rgb(0,0,0)"
    },
    right: {
        style: "thin",
        color: "rgb(0,0,0)"
    },
};


const headerCellConfig = {
    t: "s",
    s: {
        font: {
            sz: 13,
            bold: true,
            name: "Times New Roman"
        },
        border: borderCellConfig,
        alignment: {
            horizontal: "center",
            vertical: "center",
            wrapText: true,
        }
    }
}


const boldDataCellConfig = {
    t: "s",
    s: {
        font: {
            bold: true,
            name: "Times New Roman"
        },
        border: borderCellConfig,
        alignment: {
            horizontal: "center",
            vertical: "center",
            wrapText: true,
        }
    }
}

const dataCellConfig = {
    t: "s",
    s: {
        font: {
            name: "Times New Roman"
        },
        border: borderCellConfig,
        alignment: {
            horizontal: "center",
            vertical: "center",
            wrapText: true,
        }
    }
}

const section2TitleCellConfig = {
    t: "s",
    s: {
        font: {
            italic: true,
            sz: 13,
            bold: true,
            name: "Times New Roman"
        },
        border: borderCellConfig,
    }
}


const columnWidthConfig = [
    //Ngày
    {
        wch: 12
    },
    // Điểm đổ
    {
        wch: 16
    },
    // Số xe
    {
        wch: 12
    },
    // Tài xế
    {
        wch: 20
    },
    // Loại xe
    {
        wch: 10
    },
    // Chuyến
    {
        wch: 8
    },
    // Trọng L vào
    {
        wch: 8
    },
    // Trọng L ra
    {
        wch: 8
    },
    // KL Thực
    {
        wch: 8
    },
    // KLCP
    {
        wch: 8
    },
    // KLNT
    {
        wch: 8
    },
    // KL đã cắt
    {
        wch: 8
    },
    // Cự ly
    {
        wch: 8
    },
    // TKm Thực chạy
    {
        wch: 8
    },
    // KL nghiệm thu
    {
        wch: 8
    },
    // Giờ xuất bến
    {
        wch: 8
    },
    // Giờ vào
    {
        wch: 8
    },
    // Giờ ra
    {
        wch: 8
    },
    // Ngày đêm
    {
        wch: 8
    },
    // Số phiếu Th
    {
        wch: 10
    },
    // Số phiếu cân
    {
        wch: 16
    },
    // Chi nhánh
    {
        wch: 10
    },
]


const AllStationsSummaryHeaderRow = [{
    ...headerCellConfig,
    v: "Ngày",
}, {
    ...headerCellConfig,
    v: "Điểm đổ"
}, {
    ...headerCellConfig,
    v: "Số xe"
},
{
    ...headerCellConfig,
    v: "Tài xế"
}, {
    ...headerCellConfig,
    v: "Loại xe"
}, {
    ...headerCellConfig,
    v: "Chuyến"
}, {
    ...headerCellConfig,
    v: "Trọng L vào"
}, {
    ...headerCellConfig,
    v: "Trọng L ra"
}, {
    ...headerCellConfig,
    v: "KL thực chạy"
}, {
    ...headerCellConfig,
    v: "KLCP"
}, {
    ...headerCellConfig,
    v: "KLNT"
}, {
    ...headerCellConfig,
    v: "KL đã cắt"
},
{
    ...headerCellConfig,
    v: "Cự ly"
},
{
    ...headerCellConfig,
    v: "TKm thực chạy"
},
{
    ...headerCellConfig,
    v: "TKm nghiệm thu"
},
{
    ...headerCellConfig,
    v: "Giờ xuất bến"
},
{
    ...headerCellConfig,
    v: "Giờ vào"
},
{
    ...headerCellConfig,
    v: "Giờ ra"
},
{
    ...headerCellConfig,
    v: "Ngày\nĐêm"
},
{
    ...headerCellConfig,
    v: "Số phiếu TH"
},
{
    ...headerCellConfig,
    v: "Số phiếu cân"
},
{
    ...headerCellConfig,
    v: "Chi nhánh"
},
]

const SpecificStationSummaryHeaderRow = [{
    ...headerCellConfig,
    v: "STT",
}, {
    ...headerCellConfig,
    v: "Số phiếu cân"
}, {
    ...headerCellConfig,
    v: "Biển kiểm soát"
},
{
    ...headerCellConfig,
    v: "Nguồn hàng"
},
{
    ...headerCellConfig,
    v: "Trọng tải hàng vượt tải 10% (tấn)"
},
{
    ...headerCellConfig,
    v: "Khối lượng vận chuyển theo phiếu cân (tấn)"
},
{
    ...headerCellConfig,
    v: "Khối lượng được nghiệm thu -Khối lượng sau khi đã cắt trừ (tấn)"
},
{
    ...headerCellConfig,
    v: "Ghi chú"
},
{
    ...headerCellConfig,
    v: "Phiếu lỗi"
}
]

const SpecificStationSummaryHeaderRowMaxIndex = SpecificStationSummaryHeaderRow.length - 1;

const DAY_HISTORY_TITLE_INDEX = 2;

function buildAllStationsSummaryHeaderRow() {
    return [...AllStationsSummaryHeaderRow];
}

function buildSpecificStationSummaryHeaderRow() {
    return [...SpecificStationSummaryHeaderRow];
}


function calculateKLDC(klnt, kltc) {
    if (!klnt || !kltc || isNaN(klnt) || isNaN(kltc)) {
        return '';
    }
    return +(kltc - klnt).toFixed(3);
}

function calculateTKMThucChay(kltc, distance) {
    if (!kltc || !distance || isNaN(kltc) || isNaN(distance)) {
        return '';
    }
    return +(kltc * distance).toFixed(3);
}


function calculateTKMNghiemThu(klnt, distance) {
    if (!klnt || !distance || isNaN(klnt) || isNaN(distance)) {
        return '';
    }
    return +(klnt * distance).toFixed(3);
}




function isDayHistory(timeString) {
    let timeObj;
    timeObj = dateTimeHelper.ParseTimeStringToTimeJson(timeString);
    if (timeObj === null) {
        alert("Error");
        throw new Error("");
    }

    return timeObj.hour >= 6 && timeObj.hour < 22;
}


function formatStationName(stationName) {
    return stationName
        .replace('trạm', '')
        .replace('Trạm', '')
}


function buildAllStationsSummaryDataRow(rowNum, history, timeInDate, distance) {
    let row = [];

    const klcut = calculateKLDC(history.klnt, history.kltc);
    const tkmthucchay = calculateTKMThucChay(history.kltc, distance);
    const tkmnghiemthu = calculateTKMNghiemThu(history.klnt, distance);

    //Ngày
    row.push({
        ...dataCellConfig,
        v: history.date
    });

    //Điểm đổ
    row.push({
        ...dataCellConfig,
        v: formatStationName(history.stationName)
    });

    //Số xe
    row.push({
        ...dataCellConfig,
        v: history.vehicleLicensePlate || ''
    });

    //Tài xế
    row.push({
        ...dataCellConfig,
        v: ConvertToName(history.vehicleDriverName)
    });

    //Loại xe
    row.push({
        ...dataCellConfig,
        v: history.vehicleType || ''
    });

    //Chuyến
    row.push({
        ...dataCellConfig,
        v: 1
    });

    //Trọng L vào
    row.push({
        ...dataCellConfig,
        v: history.weightInAsKilo || ''
    });


    //Trọng L ra
    row.push({
        ...dataCellConfig,
        v: history.weightOutAsKilo || ''
    });

    //KL thực chạy
    row.push({
        ...dataCellConfig,
        v: history.kltc || ''
    });

    //KLCP
    row.push({
        ...dataCellConfig,
        v: history.klcp || ''
    });

    //KLNT
    row.push({
        ...dataCellConfig,
        v: history.klnt || ''
    });

    //KL đã cắt
    row.push({
        ...dataCellConfig,
        v: klcut || ''
    });

    //Cự ly
    row.push({
        ...dataCellConfig,
        v: distance || ''
    });

    //TKm thực chạy
    row.push({
        ...dataCellConfig,
        v: tkmthucchay || ''
    });

    //TKm nghiệm thu
    row.push({
        ...dataCellConfig,
        v: tkmnghiemthu || ''
    });

    //Giờ xuất bến
    row.push({
        ...dataCellConfig,
        v: history.outStationTime,
    });

    //Giờ vào trạm cân
    row.push({
        ...dataCellConfig,
        v: dateTimeHelper.RemoveSecondFromTimeString(history.comeInWeightStationAt) || ''
    });

    //Giờ ra trạm cân
    row.push({
        ...dataCellConfig,
        v: dateTimeHelper.RemoveSecondFromTimeString(history.exitWeightStationAt) || ''
    });

    //Ngày/Đêm
    row.push({
        ...dataCellConfig,
        v: history.isNotCompleted ? '' : (history.isDayHistory ? 'Ngày' : 'Đêm')
    });


    //Số phiếu TH
    row.push({
        ...dataCellConfig,
        v: timeInDate
    });


    //Số phiếu cân
    row.push({
        ...dataCellConfig,
        v: history.weightCertificateCode || ''
    });


    //Chi nhánh
    row.push({
        ...dataCellConfig,
        v: history.vehicleCollectionUnitShortName || ''
    });

    return row;
}


function createAllStationsSummarySheet(histories, stations) {
    const stationIdMapDistance = new Map(stations.map(station => [station.stationId, station.distanceToWeightStation]));

    let rows = [];
    rows.push(buildAllStationsSummaryHeaderRow());

    let timeInDate = 0;
    let currentDate = null;
    for (let index = 0; index < histories.length; index++) {
        if (currentDate !== histories[index].date) {
            currentDate = histories[index].date;
            timeInDate = 1;
        } else {
            timeInDate++;
        }

        rows.push(buildAllStationsSummaryDataRow(index + 2, histories[index], timeInDate, stationIdMapDistance.get(histories[index].stationId)));
    }

    let ws = XLSX.utils.aoa_to_sheet(rows);
    ws["!cols"] = columnWidthConfig;


    /* new format */
    /* change cell format of range A1:B2 */
    var range = { s: { r: 0, c: 0 }, e: { r: 1, c: 1 } };
    for (var R = range.s.r; R <= range.e.r; ++R) {
        for (var C = range.s.c; C <= range.e.c; ++C) {
            var cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
            cell.z = "@";
        }
    }

    return ws;
}

function buildSpecificStationSummaryTitleRow(stationName = "", month = 0, year = 0) {
    let row = [{
        t: "s",
        s: {
            font: {
                name: "Times New Roman",
                sz: 15,
                bold: true
            },
            alignment: {
                horizontal: "center",
                vertical: "center",
                wrapText: true,
            }
        },
        v: `CÔNG TY TNHH MỘT THÀNH VIÊN MÔI TRƯỜNG ĐÔ THỊ TP.HCM\n KHỐI LƯỢNG ĐƯỢC NGHIỆM THU CỦA LỘ TRÌNH 1(KHU VỰC 2) VÀ\n VẬN HÀNH TRẠM TRUNG CHUYỂN ${stationName.toUpperCase()} THÁNG ${month}/${year}`,
    }];

    return row;
}


function buildSpecificStationSummaryDayHistoryTitleRow(totalWeightFromWeightCertificate = 0,
    totalWeightFromVehicleLoad = 0) {
    try {

        return [{
            ...boldDataCellConfig,
            v: "Ca ngày",
        },
        {},
        {},
        {},
        {},
        {
            ...boldDataCellConfig,
            v: totalWeightFromWeightCertificate,
        },
        {
            ...boldDataCellConfig,
            v: totalWeightFromVehicleLoad,
        },
        {
            ...boldDataCellConfig,
            v: '',
        },
        {
            ...boldDataCellConfig,
            v: '',
        },
        ];
    } catch (e) {
        throw e;
    }
}

function buildSpecificStationSummaryNightHistoryTitleRow(totalWeightFromWeightCertificate = 0,
    totalWeightFromVehicleLoad = 0) {
    try {
        return [{
            ...boldDataCellConfig,
            v: "Ca đêm",
        },
        {},
        {},
        {},
        {},
        {
            ...boldDataCellConfig,
            v: totalWeightFromWeightCertificate,
        },
        {
            ...boldDataCellConfig,
            v: totalWeightFromVehicleLoad,
        },
        {
            ...boldDataCellConfig,
            v: '',
        },
        {
            ...boldDataCellConfig,
            v: '',
        },
        ];
    } catch (e) {
        throw e;
    }
}

function buildSpecificStationSummaryDayHistoryDataRow(stt, history) {

    return [{
        ...dataCellConfig,
        v: stt,
    },
    {
        ...dataCellConfig,
        v: history.weightCertificateCode,
    },
    {
        ...dataCellConfig,
        v: history.vehicleLicensePlate,
    },
    {
        ...dataCellConfig,
        v: history.stationName,
    },
    {
        ...dataCellConfig,
        v: history.klcp,
    },
    {
        ...dataCellConfig,
        v: history.kltc,
    },
    {
        ...dataCellConfig,
        v: history.klnt,
    },
    {
        ...dataCellConfig,
        v: history.date,
    },
    {
        ...dataCellConfig,
        v: ''
    }
    ];




}

function buildSpecificStationSummaryDayHistoryTotalRow(totalDayKLTC, totalDayKLNT) {
    return [{
        ...boldDataCellConfig,
        v: 'Tổng cộng',
    }, {
        ...boldDataCellConfig,
        v: '',
    },
    {
        ...boldDataCellConfig,
        v: '',
    }, {
        ...boldDataCellConfig,
        v: '',
    }, {
        ...boldDataCellConfig,
        v: 'Ca ngày',
    }, {
        ...boldDataCellConfig,
        v: totalDayKLTC,
    }, {
        ...boldDataCellConfig,
        v: totalDayKLNT,
    },
    ];
}


function buildSpecificStationSummaryNightHistoryTotalRow(totalNightKLTC, totalNightKLNT) {
    return [{
        ...boldDataCellConfig,
        v: '',
    }, {
        ...boldDataCellConfig,
        v: '',
    },
    {
        ...boldDataCellConfig,
        v: '',
    }, {
        ...boldDataCellConfig,
        v: '',
    }, {
        ...boldDataCellConfig,
        v: 'Ca đêm',
    }, {
        ...boldDataCellConfig,
        v: totalNightKLTC,
    }, {
        ...boldDataCellConfig,
        v: totalNightKLNT,
    },
    ];
}

function buildSpecificStationSummaryAllHistoryTotalRow(totalKLTC, totalKLNT) {
    return [{
        ...boldDataCellConfig,
        v: 'Tổng',
    }, {
        ...boldDataCellConfig,
        v: '',
    },
    {
        ...boldDataCellConfig,
        v: '',
    }, {
        ...boldDataCellConfig,
        v: '',
    }, {
        ...boldDataCellConfig,
        v: '',
    }, {
        ...boldDataCellConfig,
        v: totalKLTC,
    }, {
        ...boldDataCellConfig,
        v: totalKLNT,
    },
    ];
}

function buildEmptyRow() {
    return [];
}

function buildSpecificStationSummaryBottomSectionHeader1() {
    return [{
        ...headerCellConfig,
        v: 'VỊ TRÍ'
    },
    {
        ...headerCellConfig,
        v: 'KHỐI LƯỢNG NGÀY'
    }, {
        ...headerCellConfig,
        v: 'KHỐI LƯỢNG ĐÊM'
    }, {
        ...headerCellConfig,
        v: ''
    }, {
        ...headerCellConfig,
        v: 'TỔNG KHỐI LƯỢNG'
    }, {
        ...headerCellConfig,
        v: 'CỰ LY\n(Km)'
    }, {
        ...headerCellConfig,
        v: 'SẢN LƯỢNG NGÀY\n(tấn.Km)'
    },

    ];
}

function buildSpecificStationSummaryBottomSectionHeader2() {
    return [{
        ...headerCellConfig,
        v: ''
    },
    {
        ...headerCellConfig,
        v: 'Tấn'
    }, {
        ...headerCellConfig,
        v: 'Tấn'
    }, {
        ...headerCellConfig,
        v: ''
    }, {
        ...headerCellConfig,
        v: 'Tấn'
    }, {
        ...headerCellConfig,
        v: ''
    }, {
        ...headerCellConfig,
        v: ''
    },
    ];
}

function buildSpecificStationSummaryBottomSectionData() {
    return [{
        ...dataCellConfig,
        v: '1'
    },
    {
        ...dataCellConfig,
        v: '2'
    }, {
        ...dataCellConfig,
        v: '3'
    }, {
        ...dataCellConfig,
        v: ''
    }, {
        ...dataCellConfig,
        v: '4'
    }, {
        ...dataCellConfig,
        v: '5'
    }, {
        ...dataCellConfig,
        v: '6=2*5'
    },
    ];
}


function buildSpecificStationSummaryBottomSectionTitle1() {
    return [{
        ...section2TitleCellConfig,
        v: 'Khối lượng CTR được nghiệm thu'
    },
    {
        ...section2TitleCellConfig,
        v: ''
    }, {
        ...section2TitleCellConfig,
        v: ''
    }, {
        ...section2TitleCellConfig,
        v: ''
    }, {
        ...section2TitleCellConfig,
        v: ''
    }, {
        ...section2TitleCellConfig,
        v: ''
    }, {
        ...section2TitleCellConfig,
        v: ''
    },
    ];
}


function buildSpecificStationSummaryBottomSectionRow1(monthStationDayVolume, monthStationNightVolume, distance) {

    return [{
        ...dataCellConfig,
        v: 'Lộ trình 1 (Khu vực 2)'
    },
    {
        ...dataCellConfig,
        v: monthStationDayVolume
    }, {
        ...dataCellConfig,
        v: monthStationNightVolume
    }, {
        ...dataCellConfig,
        v: ''
    }, {
        ...dataCellConfig,
        v: FormatFloat(monthStationDayVolume + monthStationNightVolume)
    }, {
        ...dataCellConfig,
        v: distance
    }, {
        ...dataCellConfig,
        v: FormatFloat(monthStationDayVolume * distance)
    },
    ];
}


function buildSpecificStationSummaryBottomSectionRow2(monthContractDayVolume, monthContractNightVolume, distance) {
    return [{
        ...dataCellConfig,
        v: 'Lộ trình 1 (Khu vực 2)'
    },
    {
        ...dataCellConfig,
        v: monthContractDayVolume
    }, {
        ...dataCellConfig,
        v: monthContractNightVolume
    }, {
        ...dataCellConfig,
        v: ''
    }, {
        ...dataCellConfig,
        v: FormatFloat(monthContractDayVolume + monthContractNightVolume)
    }, {
        ...dataCellConfig,
        v: distance
    }, {
        ...dataCellConfig,
        v: FormatFloat(monthContractDayVolume * distance)
    },
    ];
}

function buildSpecificStationSummaryBottomSectionRow3(monthContractDayVolume, monthContractNightVolume) {
    const total = monthContractDayVolume + monthContractNightVolume;
    return [{
        ...dataCellConfig,
        v: 'Lộ trình 1 (Khu vực 2)'
    },
    {
        ...dataCellConfig,
        v: monthContractDayVolume
    }, {
        ...dataCellConfig,
        v: monthContractNightVolume
    }, {
        ...dataCellConfig,
        v: ''
    }, {
        ...dataCellConfig,
        v: FormatFloat(total)
    }, {
        ...dataCellConfig,
        v: ''
    }, {
        ...dataCellConfig,
        v: ''
    },
    ];
}

function buildSpecificStationSummaryBottomSectionTitle2() {
    return [{
        ...section2TitleCellConfig,
        v: 'Khối lượng theo hợp đồng của Lộ trình'
    },
    {
        ...section2TitleCellConfig,
        v: ''
    }, {
        ...section2TitleCellConfig,
        v: ''
    }, {
        ...section2TitleCellConfig,
        v: ''
    }, {
        ...section2TitleCellConfig,
        v: ''
    }, {
        ...section2TitleCellConfig,
        v: ''
    }, {
        ...section2TitleCellConfig,
        v: ''
    },
    ];
}

function buildSpecificStationSummaryBottomSectionTitle3() {
    return [{
        ...section2TitleCellConfig,
        v: 'Khối lượng vận hành TTC theo hợp đồng '
    },
    {
        ...section2TitleCellConfig,
        v: ''
    }, {
        ...section2TitleCellConfig,
        v: ''
    }, {
        ...section2TitleCellConfig,
        v: ''
    }, {
        ...section2TitleCellConfig,
        v: ''
    }, {
        ...section2TitleCellConfig,
        v: ''
    }, {
        ...section2TitleCellConfig,
        v: ''
    },
    ];
}

function buildSpecificStationSummaryMergeConfig(totalDayHistory, totalNightHistory) {
    let mergeConfig = [];

    mergeConfig.push({
        s: {
            r: 0,
            c: 0
        },
        e: {
            r: 0,
            c: SpecificStationSummaryHeaderRowMaxIndex
        }
    });

    mergeConfig.push({
        s: {
            r: DAY_HISTORY_TITLE_INDEX,
            c: 0
        },
        e: {
            r: DAY_HISTORY_TITLE_INDEX,
            c: 4
        }
    });

    const nightHistoryTitleIndex = DAY_HISTORY_TITLE_INDEX + totalDayHistory + 1;
    mergeConfig.push({
        s: {
            r: nightHistoryTitleIndex,
            c: 0
        },
        e: {
            r: nightHistoryTitleIndex,
            c: 4
        }
    });


    const totalDayHistoryRowIndex = nightHistoryTitleIndex + totalNightHistory + 1;
    const totalNightHistoryRowIndex = totalDayHistoryRowIndex + 1;
    const totalHistoryRowIndex = totalNightHistoryRowIndex + 1;
    mergeConfig.push({
        s: {
            r: totalDayHistoryRowIndex,
            c: 0
        },
        e: {
            r: totalNightHistoryRowIndex,
            c: 3
        }
    });

    mergeConfig.push({
        s: {
            r: totalHistoryRowIndex,
            c: 0
        },
        e: {
            r: totalHistoryRowIndex,
            c: 4
        }
    });

    const section2Header1RowIndex = totalHistoryRowIndex + 2;
    const section2Header2RowIndex = section2Header1RowIndex + 1;
    mergeConfig.push({
        s: {
            r: section2Header1RowIndex,
            c: 0
        },
        e: {
            r: section2Header2RowIndex,
            c: 0
        }
    });

    mergeConfig.push({
        s: {
            r: section2Header1RowIndex,
            c: 5
        },
        e: {
            r: section2Header2RowIndex,
            c: 5
        }
    });

    mergeConfig.push({
        s: {
            r: section2Header1RowIndex,
            c: 6
        },
        e: {
            r: section2Header2RowIndex,
            c: 6
        }
    });


    const section2Title1RowIndex = section2Header2RowIndex + 2;
    mergeConfig.push({
        s: {
            r: section2Title1RowIndex,
            c: 0
        },
        e: {
            r: section2Title1RowIndex,
            c: 6
        }
    });


    const section2Title2RowIndex = section2Title1RowIndex + 2;
    mergeConfig.push({
        s: {
            r: section2Title2RowIndex,
            c: 0
        },
        e: {
            r: section2Title2RowIndex,
            c: 6
        }
    });

    const section2Title3RowIndex = section2Title2RowIndex + 2;
    mergeConfig.push({
        s: {
            r: section2Title3RowIndex,
            c: 0
        },
        e: {
            r: section2Title3RowIndex,
            c: 6
        }
    });

    return mergeConfig;
}



function createSpecificStationSummarySheet(dayHistories = [], nightHistories = [], station, month, year, daysInMonth = 30) {
    let rows = [];
    let totalDayKLTC = 0;
    let totalDayKLNT = 0;
    let totalNightKLTC = 0;
    let totalNightKLNT = 0;

    dayHistories.filter(history => !history.isNotCompleted).map(history => {
        totalDayKLTC += history.kltc;
        totalDayKLNT += history.klnt;
    });

    nightHistories.filter(history => !history.isNotCompleted).map(history => {
        totalNightKLTC += history.kltc;
        totalNightKLNT += history.klnt;
    });

    totalDayKLTC = +totalDayKLTC.toFixed(2);
    totalDayKLNT = +totalDayKLNT.toFixed(2);
    totalNightKLTC = +totalNightKLTC.toFixed(2);
    totalNightKLNT = +totalNightKLNT.toFixed(2);

    const totalKLTC = +(totalDayKLTC + totalNightKLTC).toFixed(2);
    const totalKLNT = +(totalDayKLNT + totalNightKLNT).toFixed(2);

    const monthContractDayVolume = FormatFloat(((station.dayContractVolume / station.distanceToWeightStation) / station.contractDays) * daysInMonth, 2);
    const monthContractNightVolume = FormatFloat(((station.nightContractVolume / station.distanceToWeightStation) / station.contractDays) * daysInMonth, 2);

    const monthStationDayVolume = Math.min(totalDayKLNT, monthContractDayVolume);
    const monthStationNightVolume = Math.min(totalNightKLNT, monthContractNightVolume);

    rows.push(buildSpecificStationSummaryTitleRow(station.stationName, month, year));
    rows.push(buildSpecificStationSummaryHeaderRow());
    rows.push(buildSpecificStationSummaryDayHistoryTitleRow(totalDayKLTC, totalDayKLNT));

    let stt = 0;
    for (let history of dayHistories) {
        rows.push(buildSpecificStationSummaryDayHistoryDataRow(++stt, history));
    }

    rows.push(buildSpecificStationSummaryNightHistoryTitleRow(totalNightKLTC, totalNightKLNT));

    for (let history of nightHistories) {
        rows.push(buildSpecificStationSummaryDayHistoryDataRow(++stt, history));
    }

    rows.push(buildSpecificStationSummaryDayHistoryTotalRow(totalDayKLTC, totalDayKLNT));
    rows.push(buildSpecificStationSummaryNightHistoryTotalRow(totalNightKLTC, totalNightKLNT));
    rows.push(buildSpecificStationSummaryAllHistoryTotalRow(totalKLTC, totalKLNT));
    rows.push(buildEmptyRow());

    rows.push(buildSpecificStationSummaryBottomSectionHeader1());
    rows.push(buildSpecificStationSummaryBottomSectionHeader2());
    rows.push(buildSpecificStationSummaryBottomSectionData());

    rows.push(buildSpecificStationSummaryBottomSectionTitle1());
    rows.push(buildSpecificStationSummaryBottomSectionRow1(monthStationDayVolume, monthStationNightVolume, station.distanceToWeightStation));

    rows.push(buildSpecificStationSummaryBottomSectionTitle2());
    rows.push(buildSpecificStationSummaryBottomSectionRow2(monthContractDayVolume, monthContractNightVolume, station.distanceToWeightStation));

    rows.push(buildSpecificStationSummaryBottomSectionTitle3());
    rows.push(buildSpecificStationSummaryBottomSectionRow3(monthContractDayVolume, monthContractNightVolume));


    let ws = XLSX.utils.aoa_to_sheet(rows);
    ws["!merges"] = buildSpecificStationSummaryMergeConfig(dayHistories.length, nightHistories.length);
    ws["!rows"] = [{
        hpx: 70
    }];

    ws["!cols"] = [{
        wch: 13,
    }, {
        wch: 13
    }, {
        wch: 13
    }, {
        wch: 13
    }, {
        wch: 13
    }, {
        wch: 13
    }, {
        wch: 13
    }, {
        wch: 13
    }, {
        wch: 13
    }];

    return ws;
}


function handleSortHistory(a, b) {
    if (moment(a.vehicleInStationAt) < moment(b.vehicleInStationAt)) {
        return -1;
    }
    if (moment(a.vehicleInStationAt) > moment(b.vehicleInStationAt)) {
        return 1;
    }
    return 0;
}

function format(momentDate) {
    return momentDate.format("DD/MM/YYYY HH:mm");
}

function handleReduceHistoryStations(accu, history) {
    if (!accu[history.stationId] || history.isNotCompleted) {
        return accu;
    }

    const momentVehicleInStationAt = moment(history.vehicleInStationAt);

    if (momentVehicleInStationAt < accu[history.stationId].periodInfo.from
        || momentVehicleInStationAt > accu[history.stationId].periodInfo.to) {
        throw new Error(`${accu[history.stationId].stationName} chấp nhận lịch sử từ ${format(accu[history.stationId].periodInfo.from)} - ${format(accu[history.stationId].periodInfo.to)}`);
    }

    // if (!accu[history.stationId].rangeDate){

    // }

    // if (!accu[history.stationId].firstHistory) {
    //     accu[history.stationId].firstHistory = accu[history.stationId].lastHistory = history.vehicleInStationAt;
    //     moment(history.vehicleInStationAt);
    // } else if (!accu[history.stationId].lastHistory) {
    // }


    if (history.isDayHistory) {
        accu[history.stationId].dayHistories.push(history);
    } else {
        accu[history.stationId].nightHistories.push(history);
    }

    return accu;
}


function enrichHistoryData(history) {
    const klcpKilo = parseInt(history.vehicleLoad * 1.1);
    history.klcp = KilogramsToTon(klcpKilo);

    let outStationTime = moment(history.vehicleOutStationAt);
    history.outStationTime = outStationTime.isValid() ? outStationTime.format('HH:mm') : '';

    const outWeightStationDate = moment(history.vehicleInWeightStationOnDate);
    history.date = outWeightStationDate.isValid() ? outWeightStationDate.format('DD-MM-YYYY') : '';

    history.isNotCompleted = !history.exitWeightStationAt ? true : false;
    if (history.isNotCompleted) {
        return history;
    }

    history.weightInAsKilo = KilogramsToTon(history.comeInVehicleWeight);
    history.weightOutAsKilo = KilogramsToTon(history.exitVehicleWeight);
    history.kltc = +(history.weightInAsKilo - history.weightOutAsKilo).toFixed(3);
    history.klnt = Math.min(history.klcp, history.kltc);
    history.isDayHistory = history.exitWeightStationAt ? isDayHistory(history.exitWeightStationAt) : null;

    return history;
}



const getPeriodInfo = (date, activeTime) => {
    const momentDate = moment(date);
    const month = momentDate.month();
    const specificYear = momentDate.year();

    const startMoment = moment({
        year: specificYear,
        month: month,
        date: 1,
        hour: activeTime.hourStartAt,
        minute: activeTime.minuteStartAt,
    });
    const specificMonth = startMoment.format('MM');
    const daysInMonths = new Date(specificYear, specificMonth, 0).getDate();

    const endMoment = moment({
        year: specificYear,
        month: month,
        date: daysInMonths,
        hour: activeTime.hourStartAt,
        minute: activeTime.minuteStartAt,
    }).add(activeTime.minuteDuration, "minutes");

    return {
        from: startMoment,
        to: endMoment,
        daysInMonths,
        specificMonth,
        specificYear
    }
}

export default function ExportTakeOutGarbageHistoryWeightReport(histories = [], stations = []) {
    if (histories.length === 0) {
        throw new Error(`Không có dữ liệu`);
    }

    histories = [...histories]
        .map(history => enrichHistoryData(history))
        .sort(handleSortHistory);


    const firstDate = histories[0].vehicleInStationAt;

    const stationsMap = stations.reduce((accu, station) => {
        const takeOutGarbageActiveTime = station.activeTimes.find(activeTime => activeTime.actionType === 1);
        if (!takeOutGarbageActiveTime) {
            throw new Error(`Trạm ${station.stationName} thiếu thời gian hoạt động lấy rác`);
        }

        accu[station.stationId] = {
            stationName: station.stationName,
            stationId: station.stationId,
            dayHistories: [],
            nightHistories: [],
            periodInfo: getPeriodInfo(firstDate, takeOutGarbageActiveTime),
        };

        return accu;
    }, {});

    const historyGroupByStationsObj = histories.reduce(handleReduceHistoryStations, stationsMap);
    const historyGroupByStations = Object.entries(historyGroupByStationsObj).reduce((accu, [, val]) => {
        accu.push(val);
        return accu;
    }, []);

    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, createAllStationsSummarySheet(histories, stations), "Báo cáo tổng hợp");

    for (let i = 0; i < historyGroupByStations.length; i++) {
        const station = stations.find(st => st.stationId === historyGroupByStations[i].stationId);
        if (!station) {
            continue;
        }

        const period = historyGroupByStations[i].periodInfo;

        XLSX.utils.book_append_sheet(wb, createSpecificStationSummarySheet(historyGroupByStations[i].dayHistories,
            historyGroupByStations[i].nightHistories, station, period.specificMonth, period.specificYear, period.daysInMonths), historyGroupByStations[i].stationName);
    }


    XLSX.writeFile(wb, `Báo cáo khối lượng rác.xlsx`);
}