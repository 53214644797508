import logo from './logo.svg';
// import './App.css';
// import './top.css';
import SignInPage from './pages/signin/signin.page';
import DashboardPage from './pages/dashboard/dashboard.page';
import HistoryPage from './pages/history/history.page';
import TakeOutGarbageHistoryPage from './pages/history/takeOutGarbageHistory.page';
import MainLayout from './pages/main.layout';
import VehiclePage from './pages/vehicle/vehicle.page';
import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";


import {
  ToastContainer
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import ProtectedComponent from './providers/ProtectedComponent';


function App() {
  return (
    <div>
        <Routes>
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/main" element={<ProtectedComponent Component={MainLayout} />} >
            <Route path="" element={<DashboardPage />} />
            {/* <Route path="dashboard" element={<DashboardPage />} /> */}
            <Route path="history" element={<HistoryPage />} />
            <Route path="takeOutGarbageHistory" element={<TakeOutGarbageHistoryPage />} />
            <Route path="vehicles" element={<VehiclePage />} />
            <Route path="*" element={<Navigate to="/main/history" />} />
          </Route>
          <Route path="*" element={<Navigate to="/main/history" />} />
      </Routes>
      <ToastContainer/>
    </div>
    
  );
}

export default App;
