import {
    Grid,
    Button,
    Select,
    InputLabel,
    MenuItem,
    ListItemText,
    Checkbox,
    LinearProgress,
    TextField,
    Box
} from '@mui/material';
import { useEffect, useState } from 'react';
import * as collectionUnitAPI from '../../contexts/collectionUnitAPI';
import * as stationAPI from '../../contexts/stationAPI';
import {
    toast
} from 'react-toastify';
import {
    CombineDateObjectAndTimeObject,
    ConvertMinutesToMilliseconds
} from '../../utilities/dateAndTime';

import DatePicker from '../datePicker.component';
import './style.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function FilterBar({ onSearch, actionType = 0, stations = [], collectionUnits = [] }) {
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const [selectedCollectionUnitIds, setSelectedCollectionUnitIds] = useState([]);
    const [collectionUnitObj, setCollectionUnitObj] = useState({});
    const [collectionUnitIds, setCollectionUnitIds] = useState([]);
    const [inloadingCollectionUnitIds, setInloadingCollectionUnitIds] = useState(true);
    const [selectedStation, setSelectedStation] = useState(null);
    const [stationNameMapping, setStationNameMapping] = useState({});
    const [stationIds, setStationIds] = useState([]);

    function collectionUnitSelectHandle({ target: { value } }) {
        setSelectedCollectionUnitIds(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    function stationDropDownOnChange({ target: { value } }) {
        console.log("stationDropDownOnChange", value);
    }


    const ShowCollectionProgress = function () {
        return inloadingCollectionUnitIds ? <LinearProgress /> : ''
    }


    function setupCollectionUnits() {
        try {
            collectionUnits.push({
                collectionUnitId: 'null',
                collectionUnitName: 'Xe vãng lai'
            });
            let _collectionUnitIds = [];
            let _setCollectionUnitObj = {};
            collectionUnits.forEach(record => {
                _collectionUnitIds.push(record.collectionUnitId);
                _setCollectionUnitObj[record.collectionUnitId] = record.collectionUnitName;
            });

            setCollectionUnitObj(_setCollectionUnitObj)
            setCollectionUnitIds(_collectionUnitIds);
            setSelectedCollectionUnitIds(_collectionUnitIds);
            setInloadingCollectionUnitIds(false);

            return _collectionUnitIds;
        } catch (err) {
            toast.error(err.description, {
                toastId: err.code,
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    function setupStations() {
        try {
            let _stationIds = [];
            let _stationNameMapping = {};
            stations.forEach(record => {
                _stationIds.push(record.stationId);
                _stationNameMapping[record.stationId] = record.stationName;
            });

            setStationIds(_stationIds)
            setStationNameMapping(_stationNameMapping);
            setSelectedStation(stations[0] || null);

            return stations[0] || null;
        } catch (err) {
            toast.error(err.description, {
                toastId: err.code,
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    function GetActiveTimeByActionType(actionTimes, actionType) {
        if (!Array.isArray(actionTimes)) {
            return null;
        }
        return actionTimes.find(actionTime => actionTime.actionType === actionType);
    }


    async function initialize() {
        // const [_selectedCollectionUnitIds, _selectedStation] = await Promise.all([setupCollectionUnits(), setupStations()]);
        const _selectedCollectionUnitIds = setupCollectionUnits();
        const _selectedStation = setupStations();

        console.log(_selectedCollectionUnitIds, _selectedStation);
        const actionTime = GetActiveTimeByActionType(_selectedStation.activeTimes, actionType);
        if (!actionTime) {
            alert("Không thể tìm được khung giờ làm việc");
            return;
        }

        const _fromDate = CombineDateObjectAndTimeObject(new Date(), {
            hour: actionTime.hourStartAt,
            minute: actionTime.minuteStartAt
        });
        const _toDate = new Date(_fromDate.getTime() + ConvertMinutesToMilliseconds(actionTime.minuteDuration));
        console.log(_fromDate, _toDate);

        setFromDate(_fromDate);
        setToDate(_toDate);
        onSearch(JSON.parse(JSON.stringify({
            fromDate: _fromDate,
            toDate: _toDate,
            selectedCollectionUnitIds: _selectedCollectionUnitIds,
            station: _selectedStation
        })));
    }

    function onSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        onSearch(JSON.parse(JSON.stringify({
            fromDate,
            toDate,
            selectedCollectionUnitIds,
            station: selectedStation,
            vehicleLicensePlate: data.get("vehicleLicensePlate")
        })));
    }

    useEffect(() => {
        initialize();
    }, []);


    return <Box component="form" onSubmit={onSubmit}>
        <Grid container alignItems="flex-end" justifyContent="flex-start" rowSpacing={1} style={{ gridGap: 10 }}>
            <Grid item >
                <InputLabel >Từ ngày</InputLabel>
                <DatePicker selected={fromDate}
                    onChange={(date) => setFromDate(date)} />
            </Grid>
            <Grid item >
                <InputLabel >Đến ngày</InputLabel>
                <DatePicker selected={toDate}
                    onChange={(date) => setToDate(date)} />
            </Grid>
            <Grid item style={{ maxWidth: 300 }}>
                <InputLabel >Đơn vị thu gom</InputLabel>
                <Select
                    style={{ width: "100%" }}
                    multiple
                    value={selectedCollectionUnitIds}
                    onChange={collectionUnitSelectHandle}
                    renderValue={(ids) => ids.map((id) => collectionUnitObj[id]).join(', ')}
                    MenuProps={MenuProps}>
                    {
                        collectionUnitIds.map((collectionUnitId) => (
                            <MenuItem key={collectionUnitId} value={collectionUnitId}>
                                <Checkbox checked={selectedCollectionUnitIds.indexOf(collectionUnitId) > -1} />
                                <ListItemText primary={collectionUnitObj[collectionUnitId]}></ListItemText >
                            </MenuItem>
                        ))
                    }
                </Select>
            </Grid>
            <Grid item >
                <InputLabel>Biển số xe</InputLabel>
                <TextField name="vehicleLicensePlate" defaultValue=""></TextField>
            </Grid>
            <Grid item >
                <Button type="submit" variant="contained"
                    size="large" >Tìm</Button>
            </Grid>
        </Grid>
    </Box>
}