

function ConvertVietnameseStringToNormalString(str) {
    if (!str) {
        return "";
    }

    return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/đ/g, "d")
        .replace(/Đ/g, "D");
}

function ConvertToName(string) {
    if (!string) {
        return '';
    }

    let words = string.split(" ");
    words = words.map(word => {
        word.trim();
        if (word.length === 0) {
            return "";
        }

        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // console.log(words, words.filter(word => word.length !== 0).join(" "));

    return words
        .filter(word => word.length !== 0)
        .join(" ");
}

export {
    ConvertVietnameseStringToNormalString,
    ConvertToName
}