import {
    Box,
    Typography,
    IconButton,
    Grid,
    Button,
    Modal,
    LinearProgress,
} from '@mui/material';


import DataGridDefault from '../../shareComponents/dataGrid/dataGrid.component';
import ImageModalContent from './imageModalContent';
import NoteModalContent from './noteModalContent';
import UpdateOutStationTimeModalContent from './updateOutStationTimeModalContent';


import { useState, useEffect, memo } from 'react';
import ImageIcon from '@mui/icons-material/Image';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ErrorIcon from '@mui/icons-material/Error';

import * as historyAPI from '../../contexts/historyAPI';
import * as stationAPI from '../../contexts/stationAPI';
import * as collectionUnitAPI from '../../contexts/collectionUnitAPI';
import ExportHistoryLineReport from './reports/historyLineReport';

import {
    toast
} from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';

import './history.css'

import FilterBar from '../../shareComponents/filterBar/filterbar.component';
import ExportTakeOutGarbageHistoryWeightReport from './reports/takeOutGarbageHistoryWeightReport';
import ExportTakeOutGarbageHistoryTimeSlotOnDayReport from './reports/takeOutGarbageHistoryTimeSlotOnDayReportWithSignature';
import { DateTimeFormat, ConvertDateTimeToMillisecondTimeStamp, moment } from '../../utilities/moment';
import UpdateHistoryWeightComponent from './updateHistoryWeight.component';
import NewHistory from './components/new-history';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '800px',
    overflow: 'hidden',
};



const ACTION_TYPE = 1;
export default function TakeOutGarbageHistoryPage() {

    const [searchCriteria, setSearchCriteria] = useState({});
    const [histories, setHistories] = useState([]);

    const [columnConfiguration, setColumnConfiguration] = useState({
        columnHeaders: {},
        visibleColumns: {},
        sortModel: []
    });

    const [modalState, setModalState] = useState({
        open: false,
        state: null,
        data: null
    });

    const [sideData, setSideData] = useState(null);


    const ShowImageButton = memo(({ row }) => {

        async function onClick() {
            //setSelectedHistory(row);
            const images = await historyAPI.getHistoryImages(row.historyId);
            setModalState({
                open: true,
                state: "Image",
                data: images
            });
        }

        return <ImageIcon onClick={onClick} />
    });

    const ShowNoteButton = memo(({ row }) => {

        function onClick() {
            //setSelectedHistory(row);
            setModalState({
                open: true,
                state: "Note",
                data: row.note
            });
        }

        return <NoteAltIcon onClick={onClick} />
    });

    const ShowUpdateOutStationTimeButton = memo(({ row }) => {

        function onClick() {
            // setSelectedHistory(row);
            setModalState({
                open: true,
                state: "UpdateOutStationTime",
                data: row,
            });
        }

        return <ErrorIcon onClick={onClick} />
    });

    const UpdateWeightInformationButton = memo(({ row }) => {

        function onClick() {
            // setSelectedHistory(row);
            setModalState({
                open: true,
                state: "UpdateWeightStationInformation",
                data: row,
            });
        }

        return <EditIcon onClick={onClick}></EditIcon>
    });

    const handleOpenAddNewHistory = () => {
        setModalState({
            open: true,
            state: "AddNewHistory",
        });
    }

    const ModalContent = memo(({ state, data }) => {
        switch (state) {
            case "Image":
                return <ImageModalContent sxStyle={modalStyle} images={data} onClose={handleCloseModal} />
            case "Note":
                return <NoteModalContent sxStyle={modalStyle} note={data} onClose={handleCloseModal} />
            case "UpdateOutStationTime":
                return <UpdateOutStationTimeModalContent
                    sxStyle={modalStyle}
                    history={data}
                    onClose={handleCloseModal}
                    onSubmit={(dateTimeJson, setInProcess) => UpdateOutStationTimeModalContentSubmitHandler(data, dateTimeJson, setInProcess)} />
            case "UpdateWeightStationInformation":
                return <UpdateHistoryWeightComponent
                    sxStyle={modalStyle}
                    history={data}
                    onClose={handleCloseModal}
                    onAfterSuccess={(garbageWeightInfo, setInProcess) => UpdateWeightStationInformationModalContentSubmitHandler(data, garbageWeightInfo, setInProcess)} />
            case "AddNewHistory":
                return <NewHistory
                    stations={sideData.stations}
                    actionType={ACTION_TYPE}
                    onClose={handleCloseModal}
                    onAfterDone={handleAfterCreateMissedHistory}
                />
            default:
                return <span />
        }
    });


    const handleCloseModal = () => {
        setModalState({ open: false })
    }

    const handleAfterCreateMissedHistory = () => {
        handleCloseModal();
        handleSearch(searchCriteria);
    }

    async function UpdateOutStationTimeModalContentSubmitHandler(history, dateTimeJson, setInProcess) {
        setInProcess(true);
        try {

            const momentObj = moment(dateTimeJson);

            await historyAPI.updateHistoryOutStationTime({
                historyId: history.historyId,
                vehicleOutStationAt: DateTimeFormat(dateTimeJson, "YYYY-MM-DD HH:mm"),
                //vehicleOutStationAtTimeStamp: momentObj.valueOf()
            });

            history.vehicleOutStationAt = momentObj; //DateTimeFormat(dateTimeJson, "YYYY-MM-DD HH:mm")
            toast.success("Cập nhật thành công");
            setInProcess(false);
            setModalState({ open: false });
        } catch (error) {
            setInProcess(false);
            toast.error(error.description);
        }
    }


    async function UpdateWeightStationInformationModalContentSubmitHandler(history, garbageWeightInfo, setInProcess) {
        history.garbageWeight = garbageWeightInfo.garbageWeight;
        history.weightCertificateCode = garbageWeightInfo.weightCertificateCode;
        history.vehicleDriverName = garbageWeightInfo.vehicleDriverName;
        history.comeInWeightStationAt = garbageWeightInfo.comeInWeightStationAt;
        history.exitWeightStationAt = garbageWeightInfo.exitWeightStationAt;
        history.comeInVehicleWeight = garbageWeightInfo.comeInVehicleWeight;
        history.exitVehicleWeight = garbageWeightInfo.exitVehicleWeight;
        history.vehicleInWeightStationOnDate = DateTimeFormat(garbageWeightInfo.vehicleInWeightStationOnDate, 'YYYY-MM-DD');
        setModalState({ open: false });
    }


    const columns = [
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 70,
            // renderCell: params => <HistoryActionButtonGroup row={params.row} 
            renderCell: params => {
                return <div style={{ textAlign: "left" }}>
                    <UpdateWeightInformationButton row={params.row} />
                    {
                        params.row.imageCount > 0 ? <ShowImageButton row={params.row} /> : <span />
                    }
                    {
                        params.row.note ? <ShowNoteButton row={params.row} /> : <span />
                    }
                </div>
            }
        },
        {
            field: 'vehicleCollectionUnitName',
            headerName: 'Đơn vị',
            width: 200,
        },
        { field: 'vehicleDriverName', headerName: 'Tài xế', width: 150 },
        { field: 'vehicleLicensePlate', headerName: 'Biển số', width: 120 },
        {
            field: 'vehicleInStationAt',
            type: 'dateTime',
            headerName: 'Vào trạm lúc',
            width: 145,
            valueGetter: ({ value }) => value && new Date(value),
            renderCell: params => DateTimeFormat(params.value, "YYYY-MM-DD HH:mm")

        },
        {
            field: 'vehicleOutStationAt',
            type: 'dateTime',
            headerName: 'Ra trạm lúc',
            width: 145,
            valueGetter: ({ value }) => value ? new Date(value) : null,
            renderCell: params => params.value ? DateTimeFormat(params.value, "YYYY-MM-DD HH:mm") : <ShowUpdateOutStationTimeButton row={params.row} />
        },
        { field: 'vehicleLoad', headerName: 'Tải trọng', width: 100 },
        { field: 'vehicleType', headerName: 'Loại xe', width: 120 },
        { field: 'verifiedByName', headerName: 'Người xác nhận', width: 140 },
        {
            field: 'comeInWeightStationAt',
            headerName: 'Vào trạm cân',
            width: 130,
        },
        {
            field: 'exitWeightStationAt',
            headerName: 'Ra trạm cân',
            width: 130,
        },
        { field: 'garbageWeight', headerName: 'Số cân', width: 130 },
        { field: 'weightCertificateCode', headerName: 'Mã phiếu cân', width: 130 },
    ];

    const handleFilterBarSubmit = (criteria) => {
        const _criteria = {
            fromDate: new Date(criteria.fromDate),
            toDate: new Date(criteria.toDate),
            selectedCollectionUnitIds: criteria.selectedCollectionUnitIds,
            station: criteria.station,
            vehicleLicensePlate: criteria.vehicleLicensePlate,
            inprogress: true
        };

        setSearchCriteria(_criteria);
        handleSearch(_criteria);
    }

    const handleSearch = async function (criteria) {

        setSearchCriteria({ ...criteria, inprogress: true });

        try {

            const records = await historyAPI.searchHistories({
                fromDate: criteria.fromDate,
                toDate: criteria.toDate,
                collectionUnitIdStr: criteria.selectedCollectionUnitIds.join(','),
                stationId: criteria.station.stationId,
                vehicleLicensePlate: criteria.vehicleLicensePlate,
                actionType: ACTION_TYPE
            });

            records.forEach(history => history.vehicleInStationAt = ConvertDateTimeToMillisecondTimeStamp(history.vehicleInStationAt));

            setHistories(records);
            setSearchCriteria({ ...criteria, inprogress: false });
        } catch (err) {
            toast.error(err.description);
            setSearchCriteria({ ...criteria, inprogress: false });
        }
    }

    useEffect(() => {
        loadSideData();
        configColumns();
    }, []);


    const loadSideData = async () => {
        const responses = await Promise.all([stationAPI.getAll(), collectionUnitAPI.getAll()]);
        setSideData({
            stations: responses[0],
            collectionUnits: responses[1],
        });

        console.log(responses);
    }


    const configColumns = () => {
        let _visibleColumns = {};
        let _columnHeaders = {};
        columns.forEach(column => {
            _columnHeaders[column.field] = column.headerName;
            _visibleColumns[column.field] = true;
        });

        setColumnConfiguration({
            columnHeaders: _columnHeaders,
            visibleColumns: _visibleColumns
        });
    }



    function downloadExcel() {

        try {
            ExportHistoryLineReport(histories, columnConfiguration);
        } catch (err) {
            toast.error("Lỗi");
        }

    }


    async function handleExportTakeOutGarbageHistoryTimesReport() {
        try {
            await ExportTakeOutGarbageHistoryTimeSlotOnDayReport(histories, "LONG HÒA", searchCriteria.fromDate);
        } catch (err) {
            toast.error(err.description || err.message || 'Lỗi', {
                toastId: err.code,
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    async function handleExportTotalTimeVehicleTakeOutGarbageReport() {
        try {
            ExportTakeOutGarbageHistoryWeightReport(histories, sideData.stations);
        } catch (err) {
            toast.error(err.description || err.message || 'Lỗi', {
                toastId: err.code,
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    if (!sideData) {
        return "Loading";
    }

    return <Box sx={{
        height: 600,
        width: '100%'
    }}>
        <Typography variant='h3' component='h3' sx={{ mt: 3, mb: 3 }}>
            Lịch sử lấy rác
        </Typography>

        <FilterBar onSearch={handleFilterBarSubmit}
            actionType={ACTION_TYPE}
            stations={sideData.stations}
            collectionUnits={sideData.collectionUnits}
        />

        <div style={{ margin: 10 }}></div>

        <Grid container style={{ gridGap: 10 }}>
            <Grid>
                <Button variant="contained" color='secondary' size="large" onClick={handleOpenAddNewHistory} >Thêm</Button>
            </Grid>
            <Grid>
                <Button variant="contained" size="large" onClick={downloadExcel} >Tải lịch sử vào trạm</Button>
            </Grid>
            <Grid>
                <Button variant="contained" size="large" onClick={handleExportTakeOutGarbageHistoryTimesReport} >Tải báo cáo trạm</Button>
            </Grid>
            <Grid>
                <Button variant="contained" size="large" onClick={handleExportTotalTimeVehicleTakeOutGarbageReport} >Tải báo cáo khối lượng rác</Button>
            </Grid>
        </Grid>

        <LinearProgress color="secondary" style={{ visibility: searchCriteria.inprogress ? "visible" : "hidden" }} />

        <DataGridDefault
            columns={columns}
            rows={histories}
            getRowId={row => row.historyId}
            columnVisibilityModel={columnConfiguration.visibleColumns}
            onColumnVisibilityModelChange={(columnStates) => {
                setColumnConfiguration({
                    columnHeaders: columnConfiguration.columnHeaders,
                    visibleColumns: columnStates
                });
            }}
            onSortModelChange={(sortState) => {
                setColumnConfiguration({
                    columnHeaders: columnConfiguration.columnHeaders,
                    visibleColumns: columnConfiguration.visibleColumns,
                    sortModel: sortState
                });
            }}
            sortBy={[{ field: 'vehicleInStationAt', sort: 'asc' }]}
        />

        <Modal open={modalState.open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <ModalContent state={modalState.state} data={modalState.data} />
        </Modal>

    </Box>

}
