import * as request from './request';

import {
    ApiErrorHandleWrapper
} from './errorHandle';



export const getAll = ApiErrorHandleWrapper(async function() {
    const response = await request.get('/collectionUnits');
    return response;
})