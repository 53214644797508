// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
import auth from '../contexts/auth';
import {
    Navigate
} from "react-router-dom";


export default function ProtectedComponent({
  Component
}) {
  return auth.getToken() ? <Component /> : <Navigate to = "/signin" />
}