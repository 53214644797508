import moment from 'moment-timezone';
moment.tz.setDefault("Asia/Ho_Chi_Minh");

function DateTimeIsValid(dateTimeJsonObject) {
    return moment(dateTimeJsonObject).isValid();
}

function DateTimeFormat(date, format) {
    return moment(date).format(format);
}

function ConvertDateTimeToMillisecondTimeStamp(dateTime) {
    const momentObj = moment(dateTime);
    if (!momentObj.isValid()) {
        return null;
    }

    return momentObj.valueOf();
}

export {
    moment,
    DateTimeIsValid,
    DateTimeFormat,
    ConvertDateTimeToMillisecondTimeStamp
}