import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import auth from '../../contexts/auth';
import * as userAPI from '../../contexts/userAPI';
import {
  toast
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  useNavigate
} from "react-router-dom"

const theme = createTheme();

export default function SignInPage() {
  const navigate = useNavigate();

  const [isProcessing, setIsProcessing] = useState(false);

  const HandleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);
    const data = new FormData(event.currentTarget);

    try {
      const userInfo = await userAPI.getToken({
        userName: data.get('userName'),
        password: data.get('password'),
      });

      auth.storeUserInformation(userInfo);
      navigate('/main/dashboard')
    } catch (err) {
      toast.error(err.description, {
        toastId: err.code,
        position: toast.POSITION.TOP_RIGHT
      });
    } finally {
      setIsProcessing(false);
    }



  };

  useEffect(() => {
    console.log("useEffect");
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          {/* <Typography component="h1" variant="h5">
            Sign in
          </Typography> */}
          < Box component="form"
            onSubmit={
              HandleSubmit
            }
            noValidate sx={
              {
                mt: 1
              }
            } >
            <TextField
              margin="normal"
              required
              fullWidth
              id="userName"
              label="Số điện thoại"
              name="userName"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mật khẩu"
              type="password"
              id="password"
            />
            <Button
              disabled={isProcessing}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Đăng Nhập
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
        {/* <ToastContainer /> */}
      </Container>
    </ThemeProvider>
  );
}