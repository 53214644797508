function ParseFloat(str) {
    return parseFloat(str);
}


function ParseToFloat(tonStr) {
    if (!tonStr) {
        return "";
    }

    if (isNaN(ParseFloat(tonStr)) || !isFinite(tonStr)) {
        return "";
    }

    const float = ParseFloat(tonStr);
    return float;
}


function SubFloat(floatNumber1, floatNumber2) {
    return (ParseFloat(floatNumber1) - ParseFloat(floatNumber2)).toFixed(3);
}

function TonToKilo(ton) {
    return Math.round(ParseToFloat(ton) * 1000);
}

function KilogramsToTon(kilo) {
    if (!kilo) {
        return '';
    }
    const kiloNumber = parseInt(kilo);
    if (isNaN(kiloNumber)) {
        return '';
    }

    return kiloNumber / 1000;
}

function FormatFloat(floatNumber, digits = 2) {
    return +floatNumber.toFixed(digits);
}

function IsNumber(str) {
    console.log("IsNumber", str, str && !isNaN(str));
    return str && !isNaN(str);
}

export {
    ParseToFloat,
    SubFloat,
    TonToKilo,
    KilogramsToTon,
    FormatFloat,
    IsNumber
}