import * as request from './request';


export const getAll = async function() {
    const response = await request.get('/vehicles');
    return response.data;
};

export const find = async function(criteria) {
    const response = await request.get('/vehicles/find', criteria);
    return response.data;
};

export const getVehicleImages = async function(vehicleId) {
    const response = await request.get(`/vehicles/${vehicleId}/images`);
    return response.data;
};

export const addVehicleImages = async function(vehicleId, base64Arr) {
    const response = await request.post(`/vehicles/${vehicleId}/images`, base64Arr);
    return response.data;
};



export default request;