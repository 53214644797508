

function IsNotAvailable(obj) {
    return !obj;
}

function IsAvailable(obj) {
    return obj;
}


export {
    IsAvailable,
    IsNotAvailable
}