

import Excel from "exceljs";
import { saveAs } from "file-saver";
import { moment } from '../../../utilities/moment';
import { GetImageBaseString64FromImageUrl } from '../../../utilities/general';
import { ConvertVietnameseStringToNormalString, ConvertToName } from '../../../utilities/string'


const cellBorderObj = {
    top: { style: 'thin', color: { argb: 'rgb(0,0,0)' } },
    left: { style: 'thin', color: { argb: 'rgb(0,0,0)' } },
    bottom: { style: 'thin', color: { argb: 'rgb(0,0,0)' } },
    right: { style: 'thin', color: { argb: 'rgb(0,0,0)' } }
}

const prefixFirstHeaderRow = ['STT', 'TÊN LÁI XE', 'BIỂN SỐ XE'];
const leftColumnsRow = ['Tài xế\n ký tên', 'Ghi chú']
const DURATION_IN_STATION = 30;
const FIRST_COLUMN_LENGTH = prefixFirstHeaderRow.length;
const HEADER_ROW_START_AT = 4;
const LAST_COLUMN_LENGTH = leftColumnsRow.length;


function calculateRightSectionStartAt(totalColumn) {
    return totalColumn - 1 //Index start at 0
        -
        2 // Back 2 column from the last column
        -
        5 // Length of merged
}


function mergeCells(worksheet, totalColumn, timeColumnLength, dataRowCount) {
    //
    const startAt = calculateRightSectionStartAt(totalColumn);
    worksheet.mergeCells(1, 1, 1, 4);
    worksheet.mergeCells(1, startAt + 1, 1, startAt + 6);

    //
    worksheet.mergeCells(2, 1, 2, 4);
    worksheet.mergeCells(2, startAt + 1, 2, startAt + 6);

    //
    worksheet.mergeCells(3, 1, 3, totalColumn);

    //
    const dateRowStartAtColumn = calculateDateRowStartAtColumn(timeColumnLength);
    worksheet.mergeCells(4, dateRowStartAtColumn + 1, 4, dateRowStartAtColumn + 4);

    //
    const lastDynamicColumn = timeColumnLength + 3;
    const signatureColumn = lastDynamicColumn + 1;
    const noteColumn = lastDynamicColumn + 2;
    worksheet.mergeCells(5, 1, 7, 1);
    worksheet.mergeCells(5, 2, 7, 2);
    worksheet.mergeCells(5, 3, 7, 3);
    worksheet.mergeCells(5, 4, 5, lastDynamicColumn);
    worksheet.mergeCells(5, signatureColumn, 7, signatureColumn);
    worksheet.mergeCells(5, noteColumn, 7, noteColumn);
    //
    for (let column = 4; column < timeColumnLength + 3; column += 2) {
        worksheet.mergeCells(6, column, 6, column + 1);
    }

    //
    const totalRowStartAtRow = HEADER_ROW_START_AT + 4 + dataRowCount;
    worksheet.mergeCells(totalRowStartAtRow, 1, totalRowStartAtRow, noteColumn);

    //
    const signRowStartAtRow = totalRowStartAtRow + 2;
    const secondSignStartAtColumn = SIGN_COLUMN_LENGTH + 3;
    const thirdSignStartAtColumn = totalColumn - 2;

    worksheet.mergeCells(signRowStartAtRow, 1, signRowStartAtRow, 3);
    worksheet.mergeCells(signRowStartAtRow, secondSignStartAtColumn, signRowStartAtRow, thirdSignStartAtColumn - 3);
    worksheet.mergeCells(signRowStartAtRow, thirdSignStartAtColumn, signRowStartAtRow, thirdSignStartAtColumn + 2);
}



function styleCells(worksheet, totalColumn, timeColumnLength, dataRowCount) {
    // Column widths
    worksheet.getColumn(1).width = 6;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 13;
    for (let column = 4; column < timeColumnLength + 3; column++) {
        worksheet.getColumn(column).width = 10;
    }


    //
    worksheet.getRow(1).font = { name: 'Times New Roman', size: 11 };
    worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };

    //
    const startAt = calculateRightSectionStartAt(totalColumn);
    worksheet.getRow(2).font = { name: 'Times New Roman', size: 11, bold: true };
    worksheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getRow(2).getCell(startAt + 1).font = { name: 'Times New Roman', size: 11, bold: true, underline: true };

    //
    worksheet.getRow(3).font = { name: 'Times New Roman', size: 14, bold: true };
    worksheet.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };

    //
    worksheet.getRow(4).font = { name: 'Times New Roman', size: 11 };

    //
    worksheet.getRow(5).alignment = { vertical: 'middle', horizontal: 'center' };
    for (let i = 1; i < totalColumn + 1; i++) {
        worksheet.getRow(5).getCell(i).border = cellBorderObj;
    }

    //
    worksheet.getRow(6).alignment = { vertical: 'middle', horizontal: 'center' };
    for (let i = 1; i < totalColumn + 1; i++) {
        worksheet.getRow(6).getCell(i).border = cellBorderObj;
    }

    //
    worksheet.getRow(7).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    for (let i = 1; i < totalColumn + 1; i++) {
        worksheet.getRow(7).getCell(i).border = cellBorderObj;
    }

    let row = 8;
    for (; row < dataRowCount + 8; row++) {
        worksheet.getRow(row).alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getRow(row).height = 26;
        for (let i = 1; i < totalColumn + 1; i++) {
            worksheet.getRow(row).getCell(i).border = cellBorderObj;
        }
    }

    //
    worksheet.getRow(row).font = { size: 13, bold: true };
    worksheet.getRow(row).alignment = { vertical: 'middle', horizontal: 'center' };
    for (let i = 1; i < totalColumn + 1; i++) {
        worksheet.getRow(row).getCell(i).border = cellBorderObj;
    }

    //
    row += 2;
    worksheet.getRow(row).font = { size: 13, bold: true };
    worksheet.getRow(row).alignment = { vertical: 'middle', horizontal: 'center' };

}


function buildFirstLine(worksheet, totalColumn) {
    let row = ['CÔNG TY TNHH MỘT THÀNH VIÊN'];

    const startAt = calculateRightSectionStartAt(totalColumn);

    for (let index = 1; index < startAt; index++) {
        row.push("");
    }

    row.push('CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM');

    worksheet.insertRow(1, row);
}

function buildSecondLine(worksheet, totalColumn) {
    let row = ['MÔI TRƯỜNG ĐÔ THỊ TP.HCM'];

    const startAt = calculateRightSectionStartAt(totalColumn);

    for (let index = 1; index < startAt; index++) {
        row.push('');
    }

    row.push('Độc lập - Tự do - Hạnh phúc');

    worksheet.insertRow(2, row);
}


function buildTitleLine(worksheet, totalColumn, stationName = "") {
    worksheet.insertRow(3, [`SỔ THEO DÕI VẬN CHUYỂN TRẠM TRUNG CHUYỂN ${stationName.toUpperCase()}`]);

}


function buildDateRow(worksheet, timeColumnLength, date) {
    const startAtColumn = calculateDateRowStartAtColumn(timeColumnLength);

    let row = [];
    for (let index = 0; index < startAtColumn; index++) {
        row.push('');
    }

    const dateMomentObj = moment(date);
    row.push(`Ngày ${dateMomentObj.format("DD")} tháng ${dateMomentObj.format("MM")} năm ${dateMomentObj.format("YYYY")}`);

    worksheet.insertRow(4, row);
}



function buildFirstHeaderRow(worksheet, timeColumnLength) {
    let firstHeaderRow = [...prefixFirstHeaderRow];

    firstHeaderRow.push('THỜI GIAN (GIỜ/PHÚT) PHƯƠNG TIỆN TIẾP NHẬN TẠI TTC');


    const freeCell = timeColumnLength - 1;
    for (let index = 0; index < freeCell; index++) {
        firstHeaderRow.push('');
    }

    firstHeaderRow = firstHeaderRow.concat(leftColumnsRow);
    worksheet.insertRow(5, firstHeaderRow);
}

function buildSecondHeaderRow(worksheet, maxTime) {
    let row = ['', '', ''];

    for (let index = 1; index <= maxTime; index++) {
        row.push(`LƯỢT (XE) thứ ${index}`);
        row.push('');
    }

    worksheet.insertRow(6, row);
}

function buildThirdHeaderRow(worksheet, maxTime) {
    if (maxTime === 0) {
        return [];
    }

    let row = ['', '', ''];

    for (let index = 1; index <= maxTime; index++) {
        row.push('Thời gian\n vào');
        row.push('Thời gian\n ra')
    }

    worksheet.insertRow(7, row);
}


function buildDataRow(maxTime, no, vehicle) {
    let dataRow = [`\n${no}\n`, vehicle.vehicleDriverName, vehicle.vehicleLicensePlate];

    for (let index = 0; index < maxTime; index++) {
        if (vehicle.histories && vehicle.histories[index]) {
            dataRow.push(vehicle.histories[index].in);
            dataRow.push(vehicle.histories[index].out);
        } else {
            dataRow.push("");
            dataRow.push("");
        }
    }

    //Chữ ký
    //dataRow.push("");

    //Ghi chú
    //dataRow.push("");

    return dataRow;
}


function calculateDateRowStartAtColumn(timeSlotLength) {
    return timeSlotLength - 4 + FIRST_COLUMN_LENGTH + LAST_COLUMN_LENGTH;
}


function buildTotalRow(totalTrip) {
    let row = [`Tổng cộng: ${totalTrip} chuyến`];

    return row;
}

const SIGN_COLUMN_LENGTH = 3;

function buildSignRow(totalColumn) {
    let row = [];

    const secondSignStartAtColumn = SIGN_COLUMN_LENGTH + 2;
    const thirdSignStartAtColumn = totalColumn - SIGN_COLUMN_LENGTH

    for (let index = 0; index < totalColumn - 1; index++) {

        if (index === 0) {
            row.push("Cán bộ quản lý điều độ");
            continue;
        }

        if (index === secondSignStartAtColumn) {
            row.push("Nhân viên trực hay Quản lý TTC");
            continue;
        }


        if (index === thirdSignStartAtColumn) {
            row.push("Chỉ huy trưởng Công Trình");
            continue;
        }

        row.push("");
    }


    return row;
}

async function SaveExcelFile(workbook, fileName) {
    const buffer = await workbook.xlsx.writeBuffer(); //();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, fileName);
}

/**
 *  
 * @param {*} histories 
 * @returns 
 */
function sortHistory(histories) {
    return [...histories].sort((a, b) => {
        if (moment(a.vehicleInStationAt) < moment(b.vehicleInStationAt)) {
            return -1;
        }
        if (moment(a.vehicleInStationAt) > moment(b.vehicleInStationAt)) {
            return 1;
        }
        return 0;
    });
}


async function getBaseString64DriverSignaturesByDriverName(driverNames) {

    const promises = [];

    driverNames.forEach(driverName => {
        if (driverName) {
            promises.push(GetImageBaseString64FromImageUrl(`${process.env.REACT_APP_API_DOMAIN}/public/signatures/${driverName}.png`, 'image/png'));
        }

    });

    const baseString64DriverSignatures = await Promise.all(promises);

    const driverSignaturesMap = {};
    driverNames.forEach((driverName, idx) => {
        if (baseString64DriverSignatures[idx]) {
            driverSignaturesMap[driverName] = baseString64DriverSignatures[idx];
        }
    });

    return driverSignaturesMap;
}

export default async function ExportTakeOutGarbageHistoryTimeSlotOnDayReport(histories = [], stationName, date) {
    let registeredVehicleObj = {};
    let maxTime = 0;
    let totalTrip = 0;
    let driverNames = [];

    histories = sortHistory(histories);

    for (let index = 0; index < histories.length; index++) {
        const history = histories[index];
        const timeObj = {
            in: moment(history.vehicleInStationAt).format('HH:mm'),
            out: history.vehicleOutStationAt ? moment(history.vehicleOutStationAt).format('HH:mm') : '',
        };

        if (!history.vehicleId) {
            continue;
        }

        const vehicleDriverNameKey = ConvertVietnameseStringToNormalString(history.vehicleDriverName).replace(/ /g, "")
            .toLowerCase()
            .trim();
        if (vehicleDriverNameKey) {
            driverNames.push(vehicleDriverNameKey);
        }

        const key = `${history.vehicleId}${vehicleDriverNameKey}`;

        if (!registeredVehicleObj[key]) {
            registeredVehicleObj[key] = {
                vehicleDriverName: ConvertToName(history.vehicleDriverName),
                vehicleCollectionUnitShortName: history.vehicleCollectionUnitShortName || "",
                vehicleCitencoId: history.vehicleCitencoId || "",
                vehicleLicensePlate: history.vehicleLicensePlate || "",
                vehicleType: history.vehicleType || "",
                vehicleLoad: history.vehicleLoad || "",
                dailyLimit: history.dailyLimit || "",
                vehicleVerifiedBy: history.vehicleVerifiedBy || "",
                totalLoad: 0,
                vehicleDriverNameKey,
                histories: []
            };
        }

        registeredVehicleObj[key].totalLoad += history.vehicleLoad;
        registeredVehicleObj[key].histories.push(timeObj);
        maxTime = registeredVehicleObj[key].histories.length > maxTime ?
            registeredVehicleObj[key].histories.length :
            maxTime;

        totalTrip++;
    }

    const registeredVehicleHistories = Object.keys(registeredVehicleObj)
        .map((key) => registeredVehicleObj[key]);

    maxTime = maxTime < 5 ? 5 : maxTime;
    const timeColumnLength = maxTime * 2;
    const totalColumn = FIRST_COLUMN_LENGTH + LAST_COLUMN_LENGTH + timeColumnLength;
    const signatureColumnIndex = timeColumnLength + 3;

    const driverSignaturesMap = await getBaseString64DriverSignaturesByDriverName(driverNames);
    const driverSignatureImageIdsMap = {};
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Báo cáo');

    for (const driverName in driverSignaturesMap) {
        const imageId = workbook.addImage({ base64: driverSignaturesMap[driverName], extension: 'png' });
        driverSignatureImageIdsMap[driverName] = imageId;
    }

    buildFirstLine(worksheet, totalColumn);
    buildSecondLine(worksheet, totalColumn);
    buildTitleLine(worksheet, totalColumn, stationName);
    buildDateRow(worksheet, timeColumnLength, date);
    buildFirstHeaderRow(worksheet, timeColumnLength)
    buildSecondHeaderRow(worksheet, maxTime);
    buildThirdHeaderRow(worksheet, maxTime);

    let rowNumber = 8;
    for (let index = 0; index < registeredVehicleHistories.length; index++) {
        const vehicleDriverNameKey = registeredVehicleHistories[index].vehicleDriverNameKey;

        worksheet.insertRow(rowNumber, buildDataRow(maxTime, (index + 1), registeredVehicleHistories[index]));

        if (vehicleDriverNameKey && !isNaN(driverSignatureImageIdsMap[vehicleDriverNameKey])) {
            worksheet.addImage(driverSignatureImageIdsMap[vehicleDriverNameKey], { tl: { col: signatureColumnIndex, row: rowNumber - 1 }, ext: { width: 55, height: 32 } });
        }

        rowNumber++;
    }

    worksheet.insertRow(rowNumber, buildTotalRow(totalTrip));
    worksheet.insertRow(rowNumber + 1, []);
    worksheet.insertRow(rowNumber + 2, buildSignRow(totalColumn));

    mergeCells(worksheet, totalColumn, timeColumnLength, registeredVehicleHistories.length);
    styleCells(worksheet, totalColumn, timeColumnLength, registeredVehicleHistories.length);
    const dateMomentObj = moment(date);
    await SaveExcelFile(workbook, `Báo cáo khung thời gian lấy rác ${dateMomentObj.format('DD-MM-YYYY')}.xlsx`);
}