import * as XLSX from 'xlsx-js-style';
import moment from 'moment-timezone';

moment.tz.setDefault("Asia/Ho_Chi_Minh");
const DURATION_IN_STATION = 5;
const DYNAMIC_COLUMN_STAT_AT = 8;
const HEADER_ROW_START_AT = 6;
const FIRST_COLUMN_LENGTH = 8;
const LAST_COLUMN_LENGTH = 3;

const MERGE_CONFIG_INITIALIZE = [
    //
    {
        s: {
            r: 0,
            c: 0
        },
        e: {
            r: 0,
            c: 3
        }
    },
    {
        s: {
            r: 1,
            c: 0
        },
        e: {
            r: 1,
            c: 3
        }
    },
    {
        s: {
            r: 2,
            c: 0
        },
        e: {
            r: 2,
            c: 3
        }
    },


    //Vị trí
    {
        s: {
            r: 4,
            c: 0
        },
        e: {
            r: 4,
            c: 8
        }
    },
    // Ngày
    {
        s: {
            r: 5,
            c: 0
        },
        e: {
            r: 5,
            c: 8
        }
    },

    //Header
    {
        s: {
            r: HEADER_ROW_START_AT,
            c: 0
        },
        e: {
            r: HEADER_ROW_START_AT + 2,
            c: 0
        }
    },
    {
        s: {
            r: HEADER_ROW_START_AT,
            c: 1
        },
        e: {
            r: HEADER_ROW_START_AT + 2,
            c: 1
        }
    },
    {
        s: {
            r: HEADER_ROW_START_AT,
            c: 2
        },
        e: {
            r: HEADER_ROW_START_AT + 2,
            c: 2
        }
    },
    {
        s: {
            r: HEADER_ROW_START_AT,
            c: 3
        },
        e: {
            r: HEADER_ROW_START_AT + 2,
            c: 3
        }
    },
    {
        s: {
            r: HEADER_ROW_START_AT,
            c: 4
        },
        e: {
            r: HEADER_ROW_START_AT + 2,
            c: 4
        }
    },
    {
        s: {
            r: HEADER_ROW_START_AT,
            c: 5
        },
        e: {
            r: HEADER_ROW_START_AT + 2,
            c: 5
        }
    },
    {
        s: {
            r: HEADER_ROW_START_AT,
            c: 6
        },
        e: {
            r: HEADER_ROW_START_AT + 2,
            c: 6
        }
    },
    {
        s: {
            r: HEADER_ROW_START_AT,
            c: 7
        },
        e: {
            r: HEADER_ROW_START_AT + 2,
            c: 7
        }
    }
];

const TIME_COLUMN_WIDTH_CONFIG = {
    wch: 8
}

const COLUMN_WIDTH_INITIALIZE = [{
        wch: 6
    }, // STT
    {
        wch: 20
    }, // Chủ Phương Tiện
    {
        wch: 10
    }, // Đơn vị thu gom
    {
        wch: 7
    }, // Mã số
    {
        wch: 10
    }, // Biến kiểm soát
    {
        wch: 8
    }, // Loại phương tiện
    {
        wch: 11
    }, // Tải trọng(kg)
    {
        wch: 8
    }, // Số chuyến đăng ký
];


const headerBorderConfig = {
    top: {
        style: "thin",
        color: "rgb(0,0,0)"
    },
    bottom: {
        style: "thin",
        color: "rgb(0,0,0)"
    },
    left: {
        style: "thin",
        color: "rgb(0,0,0)"
    },
    right: {
        style: "thin",
        color: "rgb(0,0,0)"
    },
}

const dataBorderConfig = {
    top: {
        style: "dotted",
        color: "rgb(0,0,0)"
    },
    bottom: {
        style: "dotted",
        color: "rgb(0,0,0)"
    },
    left: {
        style: "thin",
        color: "rgb(0,0,0)"
    },
    right: {
        style: "thin",
        color: "rgb(0,0,0)"
    },
}

const dataCellConfig = {
    t: "s",
    s: {
        border: {
            ...dataBorderConfig
        },
        alignment: {
            horizontal: "center",
            vertical: "center"
        }
    }
}

const errorDataCellConfig = {
    t: "s",
    s: {
        border: {
            ...dataBorderConfig
        },
        alignment: {
            horizontal: "center",
            vertical: "center"
        },
        fill: {
            fgColor: {
                rgb: "E9E9E9"
            }
        }
    },
}

const defaultHeaderCellConfig = {
    t: "s",
    s: {
        alignment: {
            wrapText: true,
            horizontal: "center",
            vertical: "center"
        },
        border: {
            ...headerBorderConfig
        },
    }
}

const totalCellConfig = {
    t: "s",
    s: {
        font: {
            sz: 12,
            bold: true
        },
    }
}


const prefixFirstHeaderRow = [{
        ...defaultHeaderCellConfig,
        v: "STT",
    },
    {
        ...defaultHeaderCellConfig,
        v: "Chủ Phương Tiện"
    },
    {
        ...defaultHeaderCellConfig,
        v: "Đơn vị thu gom"
    },
    {
        ...defaultHeaderCellConfig,
        v: "Mã số",
        t: "s"
    },
    {
        ...defaultHeaderCellConfig,
        v: "Biến kiểm soát"
    },
    {
        ...defaultHeaderCellConfig,
        v: "Loại phương tiện"
    },
    {
        ...defaultHeaderCellConfig,
        v: "Tải trọng KG/Chuyến"
    },
    {
        ...defaultHeaderCellConfig,
        v: "Số\n chuyến\n đăng ký"
    }
]


function buildFirstLine(totalColumn) {
    let row = [{
        v: 'ỦY BAN NHÂN DÂN TP.HỒ CHÍ MINH',
        t: 's',
        s: {
            alignment: {
                horizontal: "center"
            },
            font: {
                name: "Times New Roman",
            }
        }
    }];

    const startAt = totalColumn - 1 //Index start at 0
        -
        2 // Back 2 column from the last column
        -
        7 // Length of merged 
    for (let index = 1; index < startAt; index++) {
        row.push({});
    }

    row.push({
        v: 'CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM',
        t: 's',
        s: {
            alignment: {
                horizontal: "center"
            },
            font: {
                name: "Times New Roman",
            }
        }
    });

    return row;
}

function buildSecondLine(totalColumn) {
    let row = [{
        v: 'CÔNG TY TNHH MỘT THÀNH VIÊN',
        t: 's',
        s: {
            alignment: {
                horizontal: "center"
            },
            font: {
                name: "Times New Roman",
                bold: true
            }
        }
    }];

    const startAt = totalColumn - 1 //Index start at 0
        -
        2 // Back 2 column from the last column
        -
        7 // Length of merged 

    for (let index = 1; index < startAt; index++) {
        row.push({});
    }

    row.push({
        v: 'Độc lập - Tự do - Hạnh phúc',
        t: 's',
        s: {
            alignment: {
                horizontal: "center"
            },
            font: {
                name: "Times New Roman",
                bold: true,
                underline: true
            }
        }
    });

    return row;
}

function buildThirdLine() {
    return [{
        v: 'MÔI TRƯỜNG ĐÔ THỊ TP.HCM',
        t: 's',
        s: {
            alignment: {
                horizontal: "center"
            },
            font: {
                bold: true,
                name: "Times New Roman",
                underline: true,
            }
        }
    }];
}


function buildTitleLine() {
    return [{
        v: 'DANH SÁCH PHƯƠNG TIỆN THU GOM RÁC NHẬP RÁC VÀO ĐIỂM HẸN, TRẠM TRUNG CHUYỂN',
        t: 's',
        s: {
            alignment: {
                horizontal: "center"
            },
            font: {
                bold: true,
                name: "Times New Roman",
                sz: 14
            }
        }
    }];
}


function buildLocationRow(stationName) {
    return [{
        v: `Tên trạm trung chuyển, điểm hẹn: ${stationName}`,
        t: 's',
        s: {
            font: {
                name: "Times New Roman",
                sz: 14
            }
        }
    }];
}

function buildDateRow(date) {
    const dateMomentObj = moment(date);
    return [{
        v: `Ngày ${dateMomentObj.format("DD")} tháng ${dateMomentObj.format("MM")} năm ${dateMomentObj.format("YYYY")}`,
        t: 's',
        s: {
            font: {
                name: "Times New Roman",
                sz: 14
            }
        }
    }];
}


function buildNumberRow(totalColumn) {
    let row = [];
    for (let index = 1; index <= totalColumn; index++) {
        row.push({
            v: `(${index})`,
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig,
                },
                alignment: {
                    horizontal: "center"
                },
            }
        });

    }

    return row;
}


function buildFirstHeaderRow(timeColumnLength) {
    let firstHeaderRow = [...prefixFirstHeaderRow];
    firstHeaderRow.push({
        ...defaultHeaderCellConfig,
        v: "Số chuyến thực tế (lượt/ngày)"
    });

    const freeCell = timeColumnLength - 1;
    for (let index = 0; index < freeCell; index++) {
        firstHeaderRow.push({
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        });
    }

    firstHeaderRow.push({
        ...defaultHeaderCellConfig,
        v: "Khối\n lượng\n chất thải\n ước tính"

    });


    firstHeaderRow.push({
        ...defaultHeaderCellConfig,
        v: "Khu vực thu gom"
    });

    firstHeaderRow.push({
        ...defaultHeaderCellConfig,
        v: "Ghi chú"
    });


    return firstHeaderRow;
}

function buildSecondHeaderRow(maxTime) {
    if (maxTime == 0) {
        return [];
    }


    let secondHeaderRow = [{
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        },
        {
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        },
        {
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        },
        {
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        },
        {
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        },
        {
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        },
        {
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        },
        {
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        }
    ];

    for (let index = 1; index <= maxTime; index++) {
        secondHeaderRow.push({
            ...defaultHeaderCellConfig,
            v: `Chuyến thứ ${index}`
        });
        secondHeaderRow.push({
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        });
    }

    secondHeaderRow = secondHeaderRow.concat([{
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        },
        {
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        },
        {
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        }
    ]);

    return secondHeaderRow;
}

function buildThirdHeaderRow(maxTime) {
    if (maxTime == 0) {
        return [];
    }

    let thirdHeaderRow = [{
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        },
        {
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        },
        {
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        },
        {
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        },
        {
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        },
        {
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        },
        {
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        },
        {
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        }
    ];
    for (let index = 1; index <= maxTime; index++) {
        thirdHeaderRow.push({
            t: "s",
            s: {
                alignment: {
                    wrapText: true,
                    horizontal: "center",
                    vertical: "center",
                    sz: 11
                },
                border: {
                    ...headerBorderConfig
                },
            },
            v: "Thời gian\n vào"
        });

        thirdHeaderRow.push({
            t: "s",
            s: {
                alignment: {
                    wrapText: true,
                    horizontal: "center",
                    vertical: "center",
                    sz: 11
                },
                border: {
                    ...headerBorderConfig
                },
            },
            v: "Thời gian\n ra"
        })
    }

    thirdHeaderRow = thirdHeaderRow.concat([{
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        },
        {
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        },
        {
            v: "",
            t: "s",
            s: {
                border: {
                    ...headerBorderConfig
                }
            }
        }
    ]);
    return thirdHeaderRow;
}


function buildDataRow(maxTime, no, vehicle) {

    let dataRow = [{
            ...dataCellConfig,
            v: no,
        },
        {
            ...dataCellConfig,
            v: vehicle.vehicleDriverName
        },
        {
            ...dataCellConfig,
            v: vehicle.vehicleCollectionUnitShortName
        },
        {
            ...dataCellConfig,
            v: vehicle.vehicleCitencoId
        },
        {
            ...dataCellConfig,
            v: vehicle.vehicleLicensePlate
        },
        {
            ...dataCellConfig,
            v: vehicle.vehicleType
        },
        {
            ...dataCellConfig,
            v: vehicle.vehicleLoad
        },
        {
            ...dataCellConfig,
            v: vehicle.dailyLimit
        }
    ];

    for (let index = 0; index < maxTime; index++) {
        if (vehicle.histories && vehicle.histories[index]) {
            dataRow.push({
                ...dataCellConfig,
                v: vehicle.histories[index].in
            });

            const cellStyle = vehicle.histories[index].out ? dataCellConfig : errorDataCellConfig;

            dataRow.push({
                ...cellStyle,
                v: vehicle.histories[index].out
            });
        } else {
            dataRow.push({
                ...dataCellConfig,
                v: ""
            });
            dataRow.push({
                ...dataCellConfig,
                v: ""
            });
        }
    }

    dataRow.push({
        ...dataCellConfig,
        v: vehicle.totalLoad
    });
    dataRow.push({
        v: vehicle.vehicleVerifiedBy,
        t: "s",
        s: {
            border: {
                ...dataBorderConfig
            },
            alignment: {
                wrapText: true
            }
        }
    });

    //Ghi chú
    dataRow.push({
        v: "",
        t: "s",
        s: {
            border: {
                ...dataBorderConfig
            },
            alignment: {
                wrapText: true
            }
        }
    });

    return dataRow;
}


function buildMergeConfig(timeColumnLength) {
    let mergeConfig = [...MERGE_CONFIG_INITIALIZE];

    const dynamicColumnLength = DYNAMIC_COLUMN_STAT_AT + timeColumnLength - 1

    const totalColumn = FIRST_COLUMN_LENGTH + LAST_COLUMN_LENGTH + timeColumnLength;
    // Merge cell for title
    mergeConfig.push({
        s: {
            r: 3,
            c: 0
        },
        e: {
            r: 3,
            c: totalColumn - 1
        }
    });

    //Merge cell for right section line
    const rightSectionColumnStartAt = totalColumn - 1 //Index start at 0
        -
        2 // Back 2 column from the last column
        -
        7 // Length of merged 
    mergeConfig.push({
        s: {
            r: 0,
            c: rightSectionColumnStartAt
        },
        e: {
            r: 0,
            c: rightSectionColumnStartAt + 7
        }
    });
    mergeConfig.push({
        s: {
            r: 1,
            c: rightSectionColumnStartAt
        },
        e: {
            r: 1,
            c: rightSectionColumnStartAt + 7
        }
    });


    if (timeColumnLength > 0) {
        //Merge cell Thực tế (lượt/ngày)											
        mergeConfig.push({
            s: {
                r: HEADER_ROW_START_AT,
                c: DYNAMIC_COLUMN_STAT_AT
            },
            e: {
                r: HEADER_ROW_START_AT,
                c: dynamicColumnLength
            }
        });
    }


    let startMergeAtColumn = 8;
    for (let index = 1; index <= timeColumnLength / 2; index++) {
        mergeConfig.push({
            s: {
                r: HEADER_ROW_START_AT + 1,
                c: startMergeAtColumn
            },
            e: {
                r: HEADER_ROW_START_AT + 1,
                c: startMergeAtColumn + 1
            }
        });
        startMergeAtColumn += 2;
    }


    //Merge cell Khối lượng chất thải ước tính
    mergeConfig.push({
        s: {
            r: HEADER_ROW_START_AT,
            c: dynamicColumnLength + 1
        },
        e: {
            r: HEADER_ROW_START_AT + 2,
            c: dynamicColumnLength + 1
        }
    });

    //Merge cell Khu vực thu gom
    mergeConfig.push({
        s: {
            r: HEADER_ROW_START_AT,
            c: dynamicColumnLength + 2
        },
        e: {
            r: HEADER_ROW_START_AT + 2,
            c: dynamicColumnLength + 2
        }
    });


    //Merge cell Ghi chú
    mergeConfig.push({
        s: {
            r: HEADER_ROW_START_AT,
            c: dynamicColumnLength + 3
        },
        e: {
            r: HEADER_ROW_START_AT + 2,
            c: dynamicColumnLength + 3
        }
    });

    return mergeConfig;
}

function buildColumnWidthConfig(timeColumnLength) {
    let columnWidthConfig = [...COLUMN_WIDTH_INITIALIZE];

    for (let index = 0; index < timeColumnLength; index++) {
        columnWidthConfig.push(TIME_COLUMN_WIDTH_CONFIG);
    }

    columnWidthConfig.push({
        wch: 8
    });


    columnWidthConfig.push({
        wch: 15
    });

    columnWidthConfig.push({
        wch: 8
    });


    return columnWidthConfig;
}

function buildRegisteredVehicleTotalRow(timeColumnLength, load, trip) {
    let row = [{}, {}, {}, {}, {}, {}, {}, {}];
    const freeCells = timeColumnLength - 2;
    for (let index = 0; index < freeCells; index++) {
        row.push({});
    }

    row.push({
        ...totalCellConfig,
        v: "Xe đăng ký"
    });

    row.push({});

    row.push({
        ...totalCellConfig,
        v: `${load}kg/${trip} chuyến`
    });

    return row;
}

function buildUnRegisteredVehicleTotalRow(timeColumnLength, load, trip) {
    let row = [{}, {}, {}, {}, {}, {}, {}, {}];
    const freeCells = timeColumnLength - 2;
    for (let index = 0; index < freeCells; index++) {
        row.push({});
    }

    row.push({
        ...totalCellConfig,
        v: "Xe chưa đăng ký"
    });

    row.push({});

    row.push({
        ...totalCellConfig,
        v: `${load}kg/${trip} chuyến`
    });

    return row;
}


function buildTotalRow(timeColumnLength, load, trip) {
    let row = [{}, {}, {}, {}, {}, {}, {}, {}];
    const freeCells = timeColumnLength - 2;
    for (let index = 0; index < freeCells; index++) {
        row.push({});
    }

    row.push({
        ...totalCellConfig,
        v: "Tổng cộng"

    });

    row.push({});

    row.push({
        ...totalCellConfig,
        v: `${load}kg/${trip} chuyến`
    });

    return row;
}


export const downloadStationDailyReportExcelFile = (histories = [], stationName, date) => {
    let registeredVehicleObj = {};
    let unregisteredVehicles = [];
    let maxTime = 0;
    let unregisteredVehicleTotalLoad = 0,
        unregisteredVehicleTotalTrip = 0;
    let registeredVehicleTotalLoad = 0,
        registeredVehicleTotalTrip = 0;
    const hasAnyData = histories.length > 0;
    for (let index = 0; index < histories.length; index++) {
        const history = histories[index];
        const timeObj = { in: moment(history.vehicleInStationAt).format('HH:mm'),
            out: history.vehicleOutStationAt ? moment(history.vehicleOutStationAt).format('HH:mm') : '',
        };

        if (!history.vehicleId) {
            unregisteredVehicles.push({
                vehicleDriverName: history.vehicleDriverName || "",
                vehicleCollectionUnitShortName: history.vehicleCollectionUnitShortName || "",
                vehicleCitencoId: history.vehicleCitencoId || "",
                vehicleLicensePlate: history.vehicleLicensePlate || "",
                vehicleType: history.vehicleType || "",
                vehicleLoad: history.vehicleLoad || "",
                dailyLimit: history.dailyLimit || "",
                vehicleVerifiedBy: history.vehicleVerifiedBy || "",
                totalLoad: history.vehicleLoad || 0,
                histories: [timeObj]
            });
            unregisteredVehicleTotalLoad += history.vehicleLoad;
            unregisteredVehicleTotalTrip++;
            continue;
        }

        if (!registeredVehicleObj[history.vehicleId]) {
            registeredVehicleObj[history.vehicleId] = {
                vehicleDriverName: history.vehicleDriverName || "",
                vehicleCollectionUnitShortName: history.vehicleCollectionUnitShortName || "",
                vehicleCitencoId: history.vehicleCitencoId || "",
                vehicleLicensePlate: history.vehicleLicensePlate || "",
                vehicleType: history.vehicleType || "",
                vehicleLoad: history.vehicleLoad || "",
                dailyLimit: history.dailyLimit || "",
                vehicleVerifiedBy: history.vehicleVerifiedBy || "",
                totalLoad: 0,
                histories: []
            };
        }

        registeredVehicleObj[history.vehicleId].totalLoad += history.vehicleLoad;
        registeredVehicleObj[history.vehicleId].histories.push(timeObj);

        registeredVehicleTotalLoad += history.vehicleLoad;
        registeredVehicleTotalTrip++;
        maxTime = registeredVehicleObj[history.vehicleId].histories.length > maxTime ?
            registeredVehicleObj[history.vehicleId].histories.length :
            maxTime;
    }

    var wb = XLSX.utils.book_new();
    let rows = [];

    maxTime = maxTime < 5 ? 5 : maxTime;
    const timeColumnLength = maxTime * 2;

    const totalColumn = FIRST_COLUMN_LENGTH + LAST_COLUMN_LENGTH + timeColumnLength;
    rows.push(buildFirstLine(totalColumn));
    rows.push(buildSecondLine(totalColumn));
    rows.push(buildThirdLine());
    rows.push(buildTitleLine());
    rows.push(buildLocationRow(stationName));
    rows.push(buildDateRow(date));
    rows.push(buildFirstHeaderRow(timeColumnLength));
    rows.push(buildSecondHeaderRow(maxTime));
    rows.push(buildThirdHeaderRow(maxTime));
    rows.push(buildNumberRow(totalColumn));

    let no = 1;
    Object.keys(registeredVehicleObj).forEach(vehicleId => {
        rows.push(buildDataRow(maxTime, no++, registeredVehicleObj[vehicleId]));
    });

    for (let index = 0; index < unregisteredVehicles.length; index++) {
        rows.push(buildDataRow(maxTime, no++, unregisteredVehicles[index]));
    }

    if (hasAnyData) {
        rows.push(buildRegisteredVehicleTotalRow(timeColumnLength, registeredVehicleTotalLoad, registeredVehicleTotalTrip));
        rows.push(buildUnRegisteredVehicleTotalRow(timeColumnLength, unregisteredVehicleTotalLoad, unregisteredVehicleTotalTrip));
        rows.push(buildTotalRow(timeColumnLength,
            (registeredVehicleTotalLoad + unregisteredVehicleTotalLoad),
            (registeredVehicleTotalTrip + unregisteredVehicleTotalTrip)));
    }

    var ws = XLSX.utils.aoa_to_sheet(rows);

    ws["!merges"] = buildMergeConfig(timeColumnLength);
    ws["!cols"] = buildColumnWidthConfig(timeColumnLength);



    const dateMomentObj = moment(date);

    XLSX.utils.book_append_sheet(wb, ws, "Báo cáo");
    XLSX.writeFile(wb, `Báo cáo ${dateMomentObj.format('DD-MM-YYYY')}.xlsx`);
}