import * as XLSX from 'xlsx-js-style';
import {
    DateTimeFormat
} from '../../../utilities/moment'
import {
    ConvertToName
} from '../../../utilities/string'

export default function ExportHistoryLineReport(histories, config) {
    const headerKeys = [];
    Object.keys(config.visibleColumns).forEach(key => {
        if (key !== "imageCount" &&
            config.visibleColumns[key] === true) {
            headerKeys.push(key);
        }
    });

    const excelData = [];

    //Add header
    let excelHeader = [];
    headerKeys.forEach(headerKey => {
        excelHeader.push(config.columnHeaders[headerKey]);
    });
    excelData.push(excelHeader);

    //Add data
    let data = [...histories];
    data.sort(function(a, b) {
        return a.vehicleInStationAt - b.vehicleInStationAt;
    })

    data.forEach(history => {
        let extractedData = [];
        headerKeys.forEach(headerKey => {

            if (headerKey === 'vehicleInStationAt') {
                extractedData.push(DateTimeFormat(history[headerKey], 'YYYY-MM-DD'));
            } else if (headerKey === 'vehicleDriverName') {
                extractedData.push(ConvertToName(history[headerKey]));
            } else {
                extractedData.push(history[headerKey]);
            }
        });
        excelData.push(extractedData);
    });

    var workbook = XLSX.utils.book_new();
    var worksheet = XLSX.utils.aoa_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Lich su xe vao tram");
    XLSX.writeFileXLSX(workbook, "Lich su xe vao tram.xlsx");
}