import { useState } from 'react';

import {
    Box,
    Typography,
    Grid,
    Button,
    TextField,
    InputLabel,
} from '@mui/material';

import {
    DateTimeFormat,
    ParseDateStringToDateJson,
    ParseTimeStringToTimeJson,
    moment
} from '../../utilities/dateAndTime';


const editTextFieldStyle = {
    width: '100%',
}


export default function UpdateOutStationTimeModalContent({ sxStyle, defaultDuration = 30, history, onSubmit = () => { }, onClose = () => { } }) {

    const [inProcess, setInProcess] = useState(false);
    const [errors, setErrors] = useState({});


    function recommendedOutStationTime(inStationTime) {
        const recommendedOutStationTime = moment(inStationTime).add(defaultDuration, "minute");
        return DateTimeFormat(recommendedOutStationTime, "DD/MM/YYYY HH:mm");
    }

    function collectVehicleOutStationAtFromForm(formElement) {
        const formData = new FormData(formElement);
        return formData.get('vehicleOutStationAt').trim();
    }

    function parseDateTimeStringToDateTimeJson(dateTimeString) {
        if (!dateTimeString) {
            return null;
        }

        const [dateStr, timeStr] = dateTimeString.split(' ');
        if (!dateStr || !timeStr) {
            return null;
        }

        const dateJson = ParseDateStringToDateJson(dateStr);
        if (dateJson === null) {
            return null;
        }

        const timeJson = ParseTimeStringToTimeJson(timeStr);
        if (timeJson === null) {
            return null;
        }

        return {
            ...dateJson,
            ...timeJson
        };
    }

    function IsError(error) {
        return !error ? false : true;
    }

    async function _onSubmit(event) {
        event.preventDefault();

        const vehicleOutStationAtString = collectVehicleOutStationAtFromForm(event.currentTarget);
        const dateTimeJson = parseDateTimeStringToDateTimeJson(vehicleOutStationAtString);
        if (!dateTimeJson) {
            setErrors({ vehicleOutStationAt: "Không hợp lệ" });
            return;
        }
        setErrors({});

        onSubmit(dateTimeJson, setInProcess);
    }


    return <Box sx={sxStyle}>
        <Typography id="modal-modal-title" variant="h4" component="h4">
            Cập nhật thời gian vào trạm
        </Typography>

        <br />

        <Box style={{ overflow: 'auto', maxHeight: 700 }} component="form"
            noValidate
            onSubmit={_onSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <div className="form-group">
                        <InputLabel >Biển số</InputLabel>
                        <p><b>{history.vehicleLicensePlate}</b></p>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="form-group">
                        <InputLabel >Vào trạm</InputLabel>
                        <p>{DateTimeFormat(history.vehicleInStationAt, "DD/MM/YYYY HH:mm")}</p>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="form-group">
                        <InputLabel >Rời trạm</InputLabel>
                        <TextField
                            required
                            disabled={inProcess}
                            name="vehicleOutStationAt"
                            style={editTextFieldStyle}
                            defaultValue={recommendedOutStationTime(history.vehicleInStationAt)}
                            error={IsError(errors.vehicleOutStationAt)}
                            helperText={errors.vehicleOutStationAt}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="form-group" style={{ textAlign: 'right' }}>
                        <Button
                            disabled={inProcess}
                            onClick={onClose}
                            type="button"
                            size="large"
                            style={{ marginTop: 12 }}>Đóng</Button>

                        <Button
                            disabled={inProcess}
                            variant="contained"
                            type="submit"
                            size="large"
                            style={{ marginTop: 12, marginLeft: 16 }}
                        >Cập nhật</Button>
                    </div>
                </Grid>
            </Grid>
        </Box>
    </Box>
}